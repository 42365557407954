import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllTabelaTributaria } from '../service';
import {
  LIST_TABELA_TRIBUTARIA,
  LIST_TABELA_TRIBUTARIA_PENDING,
  LIST_TABELA_TRIBUTARIA_SUCCESS,
  LIST_TABELA_TRIBUTARIA_FAILURE
} from '../actionTypes';

function* sagaListAllTabelaTributaria(action) {
  yield put({ type: LIST_TABELA_TRIBUTARIA_PENDING })

  try {
    const list = yield call(findAllTabelaTributaria)
    
    yield put({ type: LIST_TABELA_TRIBUTARIA_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_TABELA_TRIBUTARIA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllTabelaTributaria() {
  yield takeLatest(LIST_TABELA_TRIBUTARIA, sagaListAllTabelaTributaria)
}