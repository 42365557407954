import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fiscal/funrural' };
/////////////////////////////////////////////////////////////////////////////////

export function updateFunrural(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findFunrural(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

