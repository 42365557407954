export const CREATE_FUNRURAL = 'CREATE_FUNRURAL'
export const CREATE_FUNRURAL_PENDING = 'CREATE_FUNRURAL_PENDING'
export const CREATE_FUNRURAL_SUCCESS = 'CREATE_FUNRURAL_SUCCESS'
export const CREATE_FUNRURAL_FAILURE = 'CREATE_FUNRURAL_FAILURE'

export const TABLE_FUNRURAL = 'TABLE_FUNRURAL'
export const TABLE_FUNRURAL_PENDING = 'TABLE_FUNRURAL_PENDING'
export const TABLE_FUNRURAL_SUCCESS = 'TABLE_FUNRURAL_SUCCESS'
export const TABLE_FUNRURAL_FAILURE = 'TABLE_FUNRURAL_FAILURE'

export const FIND_FUNRURAL = 'FIND_FUNRURAL'
export const FIND_FUNRURAL_PENDING = 'FIND_FUNRURAL_PENDING'
export const FIND_FUNRURAL_SUCCESS = 'FIND_FUNRURAL_SUCCESS'
export const FIND_FUNRURAL_FAILURE = 'FIND_FUNRURAL_FAILURE'
