import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    dataInicial: '',
    dataFinal: '',
    cultura: null,
    talhoes: [],
    allTalhoes: false,
    hasLocalEstoquePadrao: false,
    localEstoquePadrao: null
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
    dataInicial: Yup.date()
        .required('Este campo é obrigatório'),
    dataFinal: Yup.date()
        .required('Este campo é obrigatório'),
    cultura: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    hasLocalEstoquePadrao: Yup.boolean()
        .required(),
    localEstoquePadrao: Yup.object().when('hasLocalEstoquePadrao', {
        is: true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Um local de estoque válido deve ser selecionado')
                    .required('Este campo é obrigatório'),
            }),
        }).nullable(),        
});

export const safraCadastro = {
    newRegistro,
    validation
}