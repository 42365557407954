import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fiscal/mensagemFiscal' };
/////////////////////////////////////////////////////////////////////////////////

export function createMensagemFiscal(payload) {
  return httpService.post(args, payload);
}

export function updateMensagemFiscal(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listMensagemFiscal(param) {
  return httpService.get({...args, args: param});
}

export function findMensagemFiscal(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllMensagemFiscal() {
  return httpService.get({ ...args, path: '/fiscal/mensagemFiscal/all' });
}

export function deleteMensagemFiscal(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

