import { takeLatest, call, put } from 'redux-saga/effects';

import { getProdutosEstoquePendente, reprocessarEstoquePendente } from '../service';
import {
  GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE,
  REPROCESSAR_ESTOQUE_AGRICULTURA, REPROCESSAR_ESTOQUE_AGRICULTURA_FAILURE, REPROCESSAR_ESTOQUE_AGRICULTURA_PENDING, REPROCESSAR_ESTOQUE_AGRICULTURA_SUCCESS,
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { AlertError, AlertSuccess } from '@components/common/AlertToast';
import { storage } from '@commons';

function* sagaReprocessarEstoquePendente(action) {
  yield put({ type: REPROCESSAR_ESTOQUE_AGRICULTURA_PENDING })

  try {
    const idSafra = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id;
    const idPropriedade = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    if (action.reprocessarEstoque === undefined || action.reprocessarEstoque === null || action.reprocessarEstoque) {
      yield call(reprocessarEstoquePendente, idPropriedade, idSafra)

      yield put({ type: REPROCESSAR_ESTOQUE_AGRICULTURA_SUCCESS, produtosEstoquePendente: [] })

      yield put({ type: GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE, idPropriedade, idSafra });

      AlertSuccess('Estoque reprocessado com sucesso!');
    } else {
      // apenas visualiza quantidades pendentes
      var produtosEstoquePendente = yield call(getProdutosEstoquePendente, idPropriedade, idSafra)

      yield put({ type: REPROCESSAR_ESTOQUE_AGRICULTURA_SUCCESS, produtosEstoquePendente })
    }
  } catch (error) {
    if (action.reprocessarEstoque === undefined || action.reprocessarEstoque === null || action.reprocessarEstoque) {
      AlertError('Falha ao reprocessar o estoque! Tente novamente...');
    } else {
      AlertError('Falha ao visualizar estoque! Tente novamente...');
    }

    yield put({ type: REPROCESSAR_ESTOQUE_AGRICULTURA_FAILURE })
  }
}

export default function* watchReprocessarEstoquePendente() {
  yield takeLatest(REPROCESSAR_ESTOQUE_AGRICULTURA, sagaReprocessarEstoquePendente)
}