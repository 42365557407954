import { takeLatest, put, call } from 'redux-saga/effects';
import { storage, path } from '@commons';

import {
    NEW_FILTER_MONITORAMENTO_NFE_COMPRA,
    NEW_FILTER_MONITORAMENTO_NFE_COMPRA_PENDING,
    NEW_FILTER_MONITORAMENTO_NFE_COMPRA_SUCCESS,
    NEW_FILTER_MONITORAMENTO_NFE_COMPRA_FAILURE,
    GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_SUCCESS,
    GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_FAILURE,
    CONFIG_SESSION,
} from '@handler';
import { formatDateYYYYMMDD } from '@components/common/format';
import { findAllNotasCompras, getTotalNotas } from '../service';
import { CONST_FILTER_MONITORAMENTO_NFE_COMPRA, CONST_ROWS_PER_PAGE } from '@commons/consts';
import { getNroCpfCnpjMonitoradosNFeCompra } from '@handler/fiscal/certificadoDigitalCadastro';

function* sagaNewFilterMonitoramentoNFeCompra(action) {
    try {
        yield put({ type: NEW_FILTER_MONITORAMENTO_NFE_COMPRA_PENDING })

        const user = JSON.parse(storage.get(path.sessionUser));
        let dataInicial = '';
        let dataFinal = '';
        var date = new Date();
        let where = '&importada=false';
        let newFilter = {
            notasImportadas: 'A_IMPORTAR',
            emitente: null,
            numeroNf: '',
            nomeEmitenteNf: '',
            dataInicial: '',
            dataFinal: '',
            rows: CONST_ROWS_PER_PAGE,
            page: 0,
            where: '&importada=false'
        };

        let filterSaved = sessionStorage.getItem(CONST_FILTER_MONITORAMENTO_NFE_COMPRA) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_MONITORAMENTO_NFE_COMPRA)) : null;

        if (filterSaved === null || filterSaved === undefined) {
            // seta data do mes na primeira vez que entra na pagina
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

            newFilter = {
                ...newFilter,
                dataInicial,
                dataFinal,
            };
        } else {
            // se seta filtro manual
            if (action.newFilter) {
                // seta filtro indicado
                // typePeriodo = 0 -> periodo manual
                // typePeriodo = 1 -> hoje
                // typePeriodo = 2 -> esta semana
                // typePeriodo = 3 -> mes passado
                // typePeriodo = 4 -> este mes
                // typePeriodo = 5 -> proximo mes
                // typePeriodo = 6 -> este ano
                if (action.typePeriodo === undefined || action.typePeriodo === 0) {
                    dataInicial = action.args.dataInicial;
                    dataFinal = action.args.dataFinal;
                } else if (action.typePeriodo === 1) {
                    dataInicial = formatDateYYYYMMDD(new Date(date));
                    dataFinal = formatDateYYYYMMDD(new Date(date));
                }
                else if (action.typePeriodo === 2) {
                    const primeiro = date.getDate() - date.getDay();

                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));
                } else if (action.typePeriodo === 3) {
                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));
                } else if (action.typePeriodo === 4) {
                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));
                } else if (action.typePeriodo === 5) {
                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));
                } else if (action.typePeriodo === 6) {
                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));
                }

                //situacao duplicata
                if (action.args.notasImportadas !== undefined) {
                    if (action.args.notasImportadas === 'TODAS') {
                        where = '';
                    } else
                        if (action.args.notasImportadas === 'IMPORTADA') {
                            where = '&importada=true';
                        } else {
                            where = '&importada=false';
                        }
                }
                if (action.args.emitente !== null && action.args.emitente !== undefined && action.args.emitente !== '') {
                    where = where + '&cpfCnpj=' + (action.args.emitente.tipoPessoa === 'FISICA' ? action.args.emitente.cpf.replace(/\D/g, '') : action.args.emitente.cnpj.replace(/\D/g, ''));
                }
                if (action.args.numeroNf !== null && action.args.numeroNf !== undefined && action.args.numeroNf !== '') {
                    where = where + '&numeroNf=' + action.args.numeroNf;
                }
                if (action.args.nomeEmitenteNf !== null && action.args.nomeEmitenteNf !== undefined && action.args.nomeEmitenteNf !== '') {
                    where = where + '&nomeEmitenteNf=' + action.args.nomeEmitenteNf;
                }

                newFilter = {
                    ...newFilter,
                    ...action.args,
                    dataInicial,
                    dataFinal,
                    where: where
                };
            } else {
                // busca filtro salvo
                if (filterSaved.notasImportadas !== undefined) {
                    if (filterSaved.notasImportadas === 'TODAS') {
                        where = '';
                    } else
                        if (filterSaved.notasImportadas === 'IMPORTADA') {
                            where = '&importada=true';
                        } else {
                            where = '&importada=false';
                        }
                }
                if (filterSaved.emitente !== null && filterSaved.emitente !== undefined && filterSaved.emitente !== '') {
                    where = where + '&cpfCnpj=' + (filterSaved.emitente.tipoPessoa === 'FISICA' ? filterSaved.emitente.cpf.replace(/\D/g, '') : filterSaved.emitente.cnpj.replace(/\D/g, ''));
                }
                if (filterSaved.numeroNf !== null && filterSaved.numeroNf !== undefined && filterSaved.numeroNf !== '') {
                    where = where + '&numeroNf=' + filterSaved.numeroNf;
                }
                if (filterSaved.nomeEmitenteNf !== null && filterSaved.nomeEmitenteNf !== undefined && filterSaved.nomeEmitenteNf !== '') {
                    where = where + '&nomeEmitenteNf=' + filterSaved.nomeEmitenteNf;
                }

                dataInicial = filterSaved.dataInicial;
                dataFinal = filterSaved.dataFinal;
                newFilter = {
                    ...filterSaved,
                    rows: CONST_ROWS_PER_PAGE,
                    page: 0,
                    where: where
                };
            }
        }

        var dominio = JSON.parse(storage.get(CONFIG_SESSION)).schemaSelecionado;

        // busca registros
        const list = yield call(findAllNotasCompras, dominio, dataInicial, dataFinal, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);

        sessionStorage.setItem(CONST_FILTER_MONITORAMENTO_NFE_COMPRA, JSON.stringify(newFilter));

        yield put({
            type: NEW_FILTER_MONITORAMENTO_NFE_COMPRA_SUCCESS, list: list, filter: newFilter
        });

        // busca totais
        const nroCpfCnpjMonitoradosNFeCompra = yield call(getNroCpfCnpjMonitoradosNFeCompra);
        const totalNotas = yield call(getTotalNotas, dominio, dataInicial, dataFinal, `${where}`);

        yield put({
            type: GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_SUCCESS, totais: {
                nroCpfCnpjMonitorados: nroCpfCnpjMonitoradosNFeCompra ?? 0,
                nroNotasCompras: list.totalElements ?? 0,
                totalNotasCompras: totalNotas === undefined || totalNotas === null || totalNotas === '' ? 0 : totalNotas,
            }
        });
    } catch (_) {
        yield put({ type: NEW_FILTER_MONITORAMENTO_NFE_COMPRA_FAILURE })
        yield put({ type: GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_FAILURE })
    }
}

export default function* watchNewFilterMonitoramentoNFeCompra() {
    yield takeLatest(NEW_FILTER_MONITORAMENTO_NFE_COMPRA, sagaNewFilterMonitoramentoNFeCompra)
}
