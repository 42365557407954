import { takeLatest, put, call } from 'redux-saga/effects';

import { findProdutoInsumoCadastro } from '../service';
import {
  FIND_PRODUTO_INSUMO_CADASTRO,
  FIND_PRODUTO_INSUMO_CADASTRO_PENDING,
  FIND_PRODUTO_INSUMO_CADASTRO_SUCCESS,
  FIND_PRODUTO_INSUMO_CADASTRO_FAILURE
} from '../actionTypes';
import { findAllPropriedadeCadastro } from '@handler/common/propriedadeCadastro';

function* sagaFindProdutoInsumoCadastro(action) {
  yield put({ type: FIND_PRODUTO_INSUMO_CADASTRO_PENDING, id: action.id })

  try {
    const listPropriedades = yield call(findAllPropriedadeCadastro);

    const registro = yield call(findProdutoInsumoCadastro, action.id);


    let estoqueInsumo = [];
    for (let index = 0; index < listPropriedades.length; index++) {
      const prop = listPropriedades[index];
      let propCadastrada = false;
      let idPropCadastrada;

      for (let j = 0; j < registro.produtoInsumoEstoques.length; j++) {
        if (registro.produtoInsumoEstoques[j].propriedade.id === prop.id) {
          propCadastrada = true;
          idPropCadastrada = j;
        }
      }

      if (!propCadastrada) {
        estoqueInsumo = estoqueInsumo.concat({
          propriedade: prop,
          estoqueAtual: 0.0,
        })
      } else {
        estoqueInsumo = estoqueInsumo.concat({
          ...registro.produtoInsumoEstoques[idPropCadastrada]
        })
      }
    }
    yield put({
      type: FIND_PRODUTO_INSUMO_CADASTRO_SUCCESS, registro: {
        unidadeAplicacao: null,
        ...registro,
        produtoInsumoEstoques: estoqueInsumo,
        dadosFiscaisItemNfe: {
          informarDadosNfe: false,
          nomeItemNF: '',
          ncm: '',
          ncmEx: '',
          cest: '',
          gtinEan: '',
          unidadeTributavel: null,
          gtinEanTributavel: '',
          cfopEstadual: null,
          cfopInterestadual: null,
          cfopExportacao: null,
          tabelaTributaria: null,
          ...registro.dadosFiscaisItemNfe
        },
      }
    })

  } catch (error) {

    yield put({ type: FIND_PRODUTO_INSUMO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindProdutoInsumoCadastro() {
  yield takeLatest(FIND_PRODUTO_INSUMO_CADASTRO, sagaFindProdutoInsumoCadastro)
}
