import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createTabelaTributaria, updateTabelaTributaria } from '../service';
import {
  CREATE_TABELA_TRIBUTARIA,
  CREATE_TABELA_TRIBUTARIA_PENDING,
  CREATE_TABELA_TRIBUTARIA_SUCCESS,
  CREATE_TABELA_TRIBUTARIA_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateTabelaTributaria(action) {
  yield put({ type: CREATE_TABELA_TRIBUTARIA_PENDING })

  try {
    // Faz a verificação dos campos modalidade de icms e icmsSt
    const data = {
      ...action.data,
      icms: {
        situacaoTributaria: action.data.icms.situacaoTributaria == undefined || action.data.icms.alsituacaoTributariaiquota === null ? null : action.data.icms.situacaoTributaria,
        origemCst: action.data.icms.origemCst == undefined || action.data.icms.origemCst === null ? null : action.data.icms.origemCst,
        modalidade: action.data.icms?.modalidade && action.data.icms.modalidade.trim() !== ''
          ? action.data.icms.modalidade
          : null,
        baseCalculo: action.data.icms.baseCalculo == undefined || action.data.icms.baseCalculo === null || action.data.icms.baseCalculo === "" || action.data.icms.baseCalculo < 0 ? 0 : action.data.icms.baseCalculo,
        reducao: action.data.icms.reducao == undefined || action.data.icms.reducao === null || action.data.icms.reducao === "" || action.data.icms.reducao < 0 ? 0 : action.data.icms.reducao,
        aliquota: action.data.icms.aliquota == undefined || action.data.icms.aliquota === null || action.data.icms.aliquota === "" || action.data.icms.aliquota < 0 ? 0 : action.data.icms.aliquota,
        possuiDesoneracaoIcms: action.data.icms.possuiDesoneracaoIcms || false,
        motivoDesoneracaoIcms: action.data.icms.possuiDesoneracaoIcms ? action.data.icms.motivoDesoneracaoIcms : null,
      },
      icmsSt: {
        baseCalculo: action.data.icmsSt.baseCalculo == undefined || action.data.icmsSt.baseCalculo === null || action.data.icmsSt.baseCalculo === "" || action.data.icmsSt.baseCalculo < 0 ? 0 : action.data.icmsSt.baseCalculo,
        reducao: action.data.icmsSt.reducao == undefined || action.data.icmsSt.reducao === null || action.data.icmsSt.reducao === "" || action.data.icmsSt.reducao < 0 ? 0 : action.data.icmsSt.reducao,
        aliquota: action.data.icmsSt.aliquota == undefined || action.data.icmsSt.aliquota === null || action.data.icmsSt.aliquota === "" || action.data.icmsSt.aliquota < 0 ? 0 : action.data.icmsSt.aliquota,
        mva: action.data.icmsSt.mva == undefined || action.data.icmsSt.mva === null || action.data.icmsSt.mva === "" || action.data.icms.mva < 0 ? 0 : action.data.icmsSt.mva,
        modalidade: action.data.icmsSt?.modalidade && action.data.icmsSt.modalidade.trim() !== ''
          ? action.data.icmsSt.modalidade
          : null,
        possuiDesoneracaoIcmsSt: action.data.icmsSt.possuiDesoneracaoIcmsSt || false,
        motivoDesoneracaoIcmsSt: action.data.icmsSt.possuiDesoneracaoIcmsSt ? action.data.icmsSt.motivoDesoneracaoIcmsSt : null,
      },
      ipi: {
        situacaoTributaria: action.data.ipi.situacaoTributaria == undefined || action.data.ipi.alsituacaoTributariaiquota === null ? null : action.data.ipi.situacaoTributaria,
        baseCalculo: action.data.ipi.baseCalculo == undefined || action.data.ipi.baseCalculo === null || action.data.ipi.baseCalculo === "" || action.data.ipi.baseCalculo < 0 ? 0 : action.data.ipi.baseCalculo,
        aliquota: action.data.ipi.aliquota == undefined || action.data.ipi.aliquota === null || action.data.ipi.aliquota === "" || action.data.ipi.aliquota < 0 ? 0 : action.data.ipi.aliquota,
      },
      pis: {
        situacaoTributaria: action.data.pis.situacaoTributaria == undefined || action.data.pis.alsituacaoTributariaiquota === null ? null : action.data.pis.situacaoTributaria,
        baseCalculo: action.data.pis.baseCalculo == undefined || action.data.pis.baseCalculo === null || action.data.pis.baseCalculo === "" || action.data.pis.baseCalculo < 0 ? 0 : action.data.pis.baseCalculo,
        aliquota: action.data.pis.aliquota == undefined || action.data.pis.aliquota === null || action.data.pis.aliquota === "" || action.data.pis.aliquota < 0 ? 0 : action.data.pis.aliquota,
      },
      pisSt: {
        baseCalculo: action.data.pisSt.baseCalculo == undefined || action.data.pisSt.baseCalculo === null || action.data.pisSt.baseCalculo === "" || action.data.pisSt.baseCalculo < 0 ? 0 : action.data.pisSt.baseCalculo,
        aliquota: action.data.pisSt.aliquota == undefined || action.data.pisSt.aliquota === null || action.data.pisSt.aliquota === "" || action.data.pisSt.aliquota < 0 ? 0 : action.data.pisSt.aliquota,
      },
      cofins: {
        situacaoTributaria: action.data.cofins.situacaoTributaria == undefined || action.data.cofins.alsituacaoTributariaiquota === null ? null : action.data.cofins.situacaoTributaria,
        baseCalculo: action.data.cofins.baseCalculo == undefined || action.data.cofins.baseCalculo === null || action.data.cofins.baseCalculo === "" || action.data.cofins.baseCalculo < 0 ? 0 : action.data.cofins.baseCalculo,
        aliquota: action.data.cofins.aliquota == undefined || action.data.cofins.aliquota === null || action.data.cofins.aliquota === "" || action.data.cofins.aliquota < 0 ? 0 : action.data.cofins.aliquota,
      },
      cofinsSt: {
        baseCalculo: action.data.cofinsSt.baseCalculo == undefined || action.data.cofinsSt.baseCalculo === null || action.data.cofinsSt.baseCalculo === "" || action.data.cofinsSt.baseCalculo < 0 ? 0 : action.data.cofinsSt.baseCalculo,
        aliquota: action.data.cofinsSt.aliquota == undefined || action.data.cofinsSt.aliquota === null || action.data.cofinsSt.aliquota === "" || action.data.cofinsSt.aliquota < 0 ? 0 : action.data.cofinsSt.aliquota,
      },
    };

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createTabelaTributaria, data);
    } else {
      yield call(updateTabelaTributaria,
        {
          ...data,
          id: data.id
        }
      );
    }

    yield put({ type: CREATE_TABELA_TRIBUTARIA_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/fiscal/tabelaTributaria');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_TABELA_TRIBUTARIA_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateTabelaTributaria() {
  yield takeLatest(CREATE_TABELA_TRIBUTARIA, sagaCreateTabelaTributaria)
}
