import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_CERTIFICADO_DIGITAL_CADASTRO_RAPIDO,
  CANCEL_CERTIFICADO_DIGITAL_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelCertificadoDigitalCadastroRapido() {
  yield put({ type: CANCEL_CERTIFICADO_DIGITAL_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelCertificadoDigitalCadastroRapido() {
  yield takeLatest(CANCEL_CERTIFICADO_DIGITAL_CADASTRO_RAPIDO, sagaCancelCertificadoDigitalCadastroRapido)
}