import { takeLatest, put, call } from 'redux-saga/effects';

import { duplicarNFeEmissao } from '../service';
import {
  DUPLICAR_NFE_EMISSAO,
  DUPLICAR_NFE_EMISSAO_PENDING,
  DUPLICAR_NFE_EMISSAO_SUCCESS,
  DUPLICAR_NFE_EMISSAO_FAILURE
} from '../actionTypes';

function* sagaDuplicarNFeEmissao(action) {
  yield put({ type: DUPLICAR_NFE_EMISSAO_PENDING, id: action.id })

  try {
    const registro = yield call(duplicarNFeEmissao, action.id);

    yield put({ type: DUPLICAR_NFE_EMISSAO_SUCCESS, registro: registro });

  } catch (error) {

    yield put({ type: DUPLICAR_NFE_EMISSAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchDuplicarNFeEmissao() {
  yield takeLatest(DUPLICAR_NFE_EMISSAO, sagaDuplicarNFeEmissao)
}
