import * as Yup from 'yup';

const newRegistro = {
    status: true,
    descricao: '',
    mensagemFiscal: null,
    icms: {
        situacaoTributaria: null,
        origemCst: null,
        modalidade: '',
        baseCalculo: 0,
        reducao: 0,
        aliquota: 0,
        possuiDesoneracaoIcms: false,
        motivoDesoneracaoIcms: null
    },
    icmsSt: {
        baseCalculo: 0,
        reducao: 0,
        aliquota: 0,
        mva: 0,
        modalidade: '',
        possuiDesoneracaoIcmsSt: false,
        motivoDesoneracaoIcmsSt: null
    },
    ipi: {
        situacaoTributaria: null,
        baseCalculo: 0,
        aliquota: 0,
    },
    pis: {
        situacaoTributaria: null,
        baseCalculo: 0,
        aliquota: 0,
    },
    pisSt: {
        baseCalculo: 0,
        aliquota: 0,
    },
    cofins: {
        situacaoTributaria: null,
        baseCalculo: 0,
        aliquota: 0,
    },
    cofinsSt: {
        baseCalculo: 0,
        aliquota: 0,
    },
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),

    // Validação de ICMS
    icms: Yup.object().shape({
        situacaoTributaria: Yup.object()
            .nullable()
            .required('Situação Tributária é obrigatória'),
        origemCst: Yup.string()
            .nullable()
            .required('Origem CST é obrigatória'),
        modalidade: Yup.string()
            .nullable()
            .when('situacaoTributaria', {
                is: (situacaoTributaria) =>
                    situacaoTributaria && ['00', '10', '20', '51', '70', '90', '900'].includes(situacaoTributaria.codigo),
                then: Yup.string()
                    .typeError('Este campo é obrigatório')
                    .required('Este campo é obrigatório'),
                otherwise: Yup.string().nullable(),
            }),
        aliquota: Yup.number()
            .nullable()
            .when('situacaoTributaria', {
                is: (situacaoTributaria) =>
                    situacaoTributaria && ['00', '10', '20', '51', '70', '90', '900'].includes(situacaoTributaria.codigo),
                then: Yup.number()
                    .min(0, 'Valor deve ser igual ou maior que zero')
                    .required('Alíquota é obrigatória'),
                otherwise: Yup.number().nullable(),
            }),
        reducao: Yup.number()
            .nullable()
            .when('situacaoTributaria', {
                is: (situacaoTributaria) =>
                    situacaoTributaria && ['00', '10', '20', '51', '70', '90', '900'].includes(situacaoTributaria.codigo),
                then: Yup.number()
                    .min(0, 'Valor deve ser igual ou maior que zero')
                    .required('Redução é obrigatória'),
                otherwise: Yup.number().nullable(),
            }),
    }),

    // Validação de ICMS ST
    // icmsSt: Yup.object().shape({
    //     modalidade: Yup.string()
    //         .nullable()
    //         .when('icms.situacaoTributaria', {
    //             is: (situacaoTributaria) =>
    //                 situacaoTributaria && ['00', '10', '20', '51', '70', '90', '900'].includes(situacaoTributaria.codigo),
    //             then: Yup.string()
    //                 .typeError('Este campo é obrigatório')
    //                 .required('Este campo é obrigatório'),
    //             otherwise: Yup.string().nullable(),
    //         }),
    //     aliquota: Yup.number()
    //         .nullable()
    //         .when('icms.situacaoTributaria', {
    //             is: (situacaoTributaria) =>
    //                 situacaoTributaria && ['10', '30', '70', '90', '201', '202', '203', '900'].includes(situacaoTributaria.codigo),
    //             then: Yup.number()
    //                 .min(0, 'Valor deve ser igual ou maior que zero')
    //                 .required('Alíquota é obrigatória para ICMS ST'),
    //             otherwise: Yup.number().nullable(),
    //         }),
    //     reducao: Yup.number()
    //         .nullable()
    //         .when('icms.situacaoTributaria', {
    //             is: (situacaoTributaria) =>
    //                 situacaoTributaria && ['10', '30', '70', '90', '201', '202', '203', '900'].includes(situacaoTributaria.codigo),
    //             then: Yup.number()
    //                 .min(0, 'Valor deve ser igual ou maior que zero')
    //                 .required('Redução é obrigatória para ICMS ST'),
    //             otherwise: Yup.number().nullable(),
    //         }),
    //     mva: Yup.number()
    //         .nullable()
    //         .when('icms.situacaoTributaria', {
    //             is: (situacaoTributaria) =>
    //                 situacaoTributaria && ['10', '30', '70', '90', '201', '202', '203', '900'].includes(situacaoTributaria.codigo),
    //             then: Yup.number()
    //                 .min(0, 'Valor deve ser igual ou maior que zero')
    //                 .required('MVA é obrigatória para ICMS ST'),
    //             otherwise: Yup.number().nullable(),
    //         }),
    // }),

    // Validação de IPI
    ipi: Yup.object().shape({
        aliquota: Yup.number()
            .nullable()
            .min(0, 'Alíquota deve ser igual ou maior que zero')
            .required('Alíquota é obrigatória para IPI'),
        baseCalculo: Yup.number()
            .nullable()
            .min(0, 'Base de Cálculo deve ser igual ou maior que zero')
            .required('Base de Cálculo é obrigatória para IPI'),
    }),

    // Validação de PIS
    pis: Yup.object().shape({
        situacaoTributaria: Yup.object()
            .nullable()
            .required('Situação Tributária é obrigatória para PIS'),
        aliquota: Yup.number()
            .nullable()
            .min(0, 'Alíquota deve ser igual ou maior que zero')
            .required('Alíquota é obrigatória para PIS'),
        baseCalculo: Yup.number()
            .nullable()
            .min(0, 'Base de Cálculo deve ser igual ou maior que zero')
            .required('Base de Cálculo é obrigatória para PIS'),
    }),

    // Validação de PIS ST
    pisSt: Yup.object().shape({
        aliquota: Yup.number()
            .nullable()
            .min(0, 'Alíquota deve ser igual ou maior que zero')
            .required('Alíquota é obrigatória para PIS ST'),
        baseCalculo: Yup.number()
            .nullable()
            .min(0, 'Base de Cálculo deve ser igual ou maior que zero')
            .required('Base de Cálculo é obrigatória para PIS ST'),
    }),

    // Validação de COFINS
    cofins: Yup.object().shape({
        situacaoTributaria: Yup.object()
            .nullable()
            .required('Situação Tributária é obrigatória para COFINS'),
        aliquota: Yup.number()
            .nullable()
            .min(0, 'Alíquota deve ser igual ou maior que zero')
            .required('Alíquota é obrigatória para COFINS'),
        baseCalculo: Yup.number()
            .nullable()
            .min(0, 'Base de Cálculo deve ser igual ou maior que zero')
            .required('Base de Cálculo é obrigatória para COFINS'),
    }),

    // Validação de COFINS ST
    cofinsSt: Yup.object().shape({
        aliquota: Yup.number()
            .nullable()
            .min(0, 'Alíquota deve ser igual ou maior que zero')
            .required('Alíquota é obrigatória para COFINS ST'),
        baseCalculo: Yup.number()
            .nullable()
            .min(0, 'Base de Cálculo deve ser igual ou maior que zero')
            .required('Base de Cálculo é obrigatória para COFINS ST'),
    }),
});


export const tabelaTributaria = {
    newRegistro,
    validation
}