import { takeLatest, put, call } from 'redux-saga/effects';

import { emitirNFeEmissao, findNFeEmissao } from '../service';
import {
  EMITIR_NFE_EMISSAO,
  EMITIR_NFE_EMISSAO_PENDING,
  EMITIR_NFE_EMISSAO_SUCCESS,
  EMITIR_NFE_EMISSAO_FAILURE
} from '../actionTypes';
import { AlertError, AlertSuccess } from '@components/common/AlertToast';

function* sagaEmitirNFeEmissao(action) {
  yield put({ type: EMITIR_NFE_EMISSAO_PENDING, id: action.id })

  try {
    yield call(emitirNFeEmissao, action.id);

    const registro = yield call(findNFeEmissao, action.id);
    
    yield put({ type: EMITIR_NFE_EMISSAO_SUCCESS, registro: registro })

    AlertSuccess('NFe emitida com sucesso!');
  } catch (error) {
    const registro = yield call(findNFeEmissao, action.id);

    yield put({ type: EMITIR_NFE_EMISSAO_FAILURE, registro: registro })

    AlertError('Falha ao emitir a NFe! Verifique...');
  }
}

export default function* watchEmitirNFeEmissao() {
  yield takeLatest(EMITIR_NFE_EMISSAO, sagaEmitirNFeEmissao)
}
