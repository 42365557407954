import { all } from 'redux-saga/effects';

import { loginSaga, loginReducer } from './login';

import { contaAgrareSaga, contaAgrareReducer } from './contaAgrare';

import { configGeralSaga, configGeralReducer } from './config/geral';
import { propriedadeCadastroSaga, propriedadeCadastroReducer } from './common/propriedadeCadastro';
import { perfilSaga, perfilReducer } from './common/perfil';
import { usuarioSaga, usuarioReducer } from './common/usuario';

import { startSessionSaga, startSessionReducer } from './common/startSession';
import { fazendaCadastroSaga, fazendaCadastroReducer } from './common/fazendaCadastro';
import { talhaoCadastroSaga, talhaoCadastroReducer } from './common/talhaoCadastro';
import { funcionarioCargoCadastroSaga, funcionarioCargoCadastroReducer } from './common/funcionarioCargoCadastro';
import { funcionarioCadastroSaga, funcionarioCadastroReducer } from './common/funcionarioCadastro';
import { unidadeMedidaCadastroSaga, unidadeMedidaCadastroReducer } from './common/unidadeMedidaCadastro';
import { unidadeMedidaConversaoCadastroSaga, unidadeMedidaConversaoCadastroReducer } from './common/unidadeMedidaConversaoCadastro';

import { patrimonioCadastroSaga, patrimonioCadastroReducer } from './common/patrimonioCadastro';
import { patrimonioManutencaoCadastroSaga, patrimonioManutencaoCadastroReducer } from './patrimonio/manutencaoCadastro';
import { patrimonioAbastecimentoCadastroSaga, patrimonioAbastecimentoCadastroReducer } from './patrimonio/abastecimentoCadastro';

import { produtoInsumoCadastroSaga, produtoInsumoCadastroReducer } from './common/produtoInsumoCadastro';
import { clienteCadastroSaga, clienteCadastroReducer } from './common/clienteCadastro';
import { fornecedorCadastroSaga, fornecedorCadastroReducer } from './common/fornecedorCadastro';
import { transportadoraCadastroSaga, transportadoraCadastroReducer } from './common/transportadoraCadastro';
import { pluviometroDigitalSaga, pluviometroDigitalReducer } from './common/pluviometroDigital';
import { motoristaCadastroSaga, motoristaCadastroReducer } from './common/motoristaCadastro';
import { veiculoCadastroSaga, veiculoCadastroReducer } from './common/veiculoCadastro';
import { dashboardPatrimonioSaga, dashboardPatrimonioReducer } from './common/dashboardPatrimonio';
import { identificadorCadastroSaga, identificadorCadastroReducer } from './common/identificadorCadastro';
import { mapSaga, mapReducer } from './common/map';
import { calendarioSaga, calendarioReducer } from './common/calendario';
import { notificationSaga, notificationReducer } from './common/notification';

import { dashboardSaga, dashboardReducer } from './agricultura/dashboard';
import { safraCadastroSaga, safraCadastroReducer } from './agricultura/safraCadastro';
import { procedimentoCadastroSaga, procedimentoCadastroReducer } from './agricultura/procedimentoCadastro';
import { despesaDiretaCadastroSaga, despesaDiretaCadastroReducer } from './agricultura/despesaDiretaCadastro';
import { planejamentoSaga, planejamentoReducer } from './agricultura/atividadeAgricola/planejamento';
import { plantioManejoSaga, plantioManejoReducer } from './agricultura/atividadeAgricola/plantioManejo';
import { colheitaSaga, colheitaReducer } from './agricultura/atividadeAgricola/colheita';
import { culturaCadastroSaga, culturaCadastroReducer } from './agricultura/culturaCadastro';
import { produtoAgricolaCadastroSaga, produtoAgricolaCadastroReducer } from './agricultura/produtoAgricolaCadastro';
import { vendaProducaoAgricolaSaga, vendaProducaoAgricolaReducer } from './agricultura/vendaProducaoAgricola';
import { relatorioEstoqueProducaoPorPeriodoSaga, relatorioEstoqueProducaoPorPeriodoReducer } from './agricultura/relatorioEstoqueProducaoPorPeriodo';
import { implantacaoEstoqueProducaoSaga, implantacaoEstoqueProducaoReducer } from './agricultura/implantacaoEstoqueProducao';
import { baixaManualEstoqueProducaoSaga, baixaManualEstoqueProducaoReducer } from './agricultura/baixarManualEstoqueProducao';
import { transferenciaEstoqueProducaoSaga, transferenciaEstoqueProducaoReducer } from './agricultura/transferenciaEstoqueProducao';
import { estagioAplicacaoCadastroSaga, estagioAplicacaoCadastroReducer } from './agricultura/estagioAplicacaoCadastro';
import { ndviSaga, ndviReducer } from './agricultura/ndvi';

import { planoContaCadastroSaga, planoContaCadastroReducer } from './financeiro/planoContaCadastro';
import { contaCaixaCadastroSaga, contaCaixaCadastroReducer } from './financeiro/contaCaixaCadastro';
import { contaChequePreDatadoCadastroSaga, contaChequePreDatadoCadastroReducer } from './financeiro/contaChequePreDatadoCadastro';
import { contaBancoCadastroSaga, contaBancoCadastroReducer } from './financeiro/contaBancoCadastro';
import { contaCartaoCadastroSaga, contaCartaoCadastroReducer } from './financeiro/contaCartaoCadastro';
import { controleContaCaixaSaga, controleContaCaixaReducer } from './financeiro/controleContaCaixa';
import { controleContaBancoSaga, controleContaBancoReducer } from './financeiro/controleContaBanco';
import { fluxoCaixaSaga, fluxoCaixaReducer } from './financeiro/fluxoCaixa';
import { baixaContaSaga, baixaContaReducer } from './financeiro/baixarConta';
import { contaPagarReceberAbsSaga, contaPagarReceberAbsReducer } from './financeiro/contaPagarReceberAbs';
import { contaPagarSaga, contaPagarReducer } from './financeiro/contaPagar';
import { contaPagarRecorrenteSaga, contaPagarRecorrenteReducer } from './financeiro/contaPagarRecorrente';
import { contaReceberSaga, contaReceberReducer } from './financeiro/contaReceber';
import { dashboardFinanceiroSaga, dashboardFinanceiroReducer } from './financeiro/dashboard';
import { transferenciaContaBancoCaixaSaga, transferenciaContaBancoCaixaReducer } from './financeiro/transferenciaContaBancoCaixa';

import { localEstoqueCadastroSaga, localEstoqueCadastroReducer } from './estoque/localEstoqueCadastro';
import { entradaEstoqueSaga, entradaEstoqueReducer } from './estoque/entradaEstoque';
import { implantacaoEstoqueSaga, implantacaoEstoqueReducer } from './estoque/implantacaoEstoque';
import { baixarManualEstoqueSaga, baixarManualEstoqueReducer } from './estoque/baixarManualEstoque';
import { relatorioEstoqueAtualSaga, relatorioEstoqueAtualReducer } from './estoque/relatorioEstoqueAtual';
import { relatorioReposicaoEstoqueMinimoSaga, relatorioReposicaoEstoqueMinimoReducer } from './estoque/relatorioReposicaoEstoqueMinimo';
import { relatorioEstoquePorPeriodoSaga, relatorioEstoquePorPeriodoReducer } from './estoque/relatorioEstoquePorPeriodo';
import { transferenciaEstoqueSaga, transferenciaEstoqueReducer } from './estoque/transferenciaEstoque';

import { naturezaOperacaoCadastroSaga, naturezaOperacaoCadastroReducer } from './tributario/naturezaOperacaoCadastro';

import { procedimentoPecuarioCadastroSaga, procedimentoPecuarioCadastroReducer } from './pecuaria/procedimentoPecuarioCadastro';
import { animalEspecieCadastroSaga, animalEspecieCadastroReducer } from './pecuaria/animalEspecieCadastro';
import { animalRacaCadastroSaga, animalRacaCadastroReducer } from './pecuaria/animalRacaCadastro';
import { animalPelagemCadastroSaga, animalPelagemCadastroReducer } from './pecuaria/animalPelagemCadastro';
import { animalCategoriaCadastroSaga, animalCategoriaCadastroReducer } from './pecuaria/animalCategoriaCadastro';
import { animalLoteCadastroSaga, animalLoteCadastroReducer } from './pecuaria/animalLoteCadastro';
import { animalCadastroSaga, animalCadastroReducer } from './pecuaria/animalCadastro';
import { campoCadastroSaga, campoCadastroReducer } from './pecuaria/campoCadastro';
import { anoPecuarioCadastroSaga, anoPecuarioCadastroReducer } from './pecuaria/anoPecuarioCadastro';
import { manejoPlanejamentoCadastroSaga, manejoPlanejamentoCadastroReducer } from './pecuaria/manejoPlanejamentoCadastro';
import { manejoExecucaoCadastroSaga, manejoExecucaoCadastroReducer } from './pecuaria/manejoExecucaoCadastro';
import { relatorioMovimentacaoAnimalSaga, relatorioMovimentacaoAnimalReducer } from './pecuaria/relatorioMovimentacaoAnimal';
import { evolucaoAnimalSaga, evolucaoAnimalReducer } from './pecuaria/evolucaoAnimal';
import { transferenciaAnimalSaga, transferenciaAnimalReducer } from './pecuaria/transferenciaAnimal';
import { compraAnimalSaga, compraAnimalReducer } from './pecuaria/compraAnimal';
import { vendaAnimalSaga, vendaAnimalReducer } from './pecuaria/vendaAnimal';
import { implantacaoAnimalSaga, implantacaoAnimalReducer } from './pecuaria/implantacaoAnimal';
import { dashboardPecuariaSaga, dashboardPecuariaReducer } from './pecuaria/dashboard';
import { outrasSaidaAnimalSaga, outrasSaidaAnimalReducer } from './pecuaria/outrasSaidaAnimal';

import { emitenteCadastroSaga, emitenteCadastroReducer } from './fiscal/emitenteCadastro';
import { certificadoDigitalCadastroSaga, certificadoDigitalCadastroReducer } from './fiscal/certificadoDigitalCadastro';
import { monitoramentoNFeCompraSaga, monitoramentoNFeCompraReducer } from './fiscal/monitoramentoNFeCompra';
import { nfeEmissaoSaga, nfeEmissaoReducer } from './fiscal/nfeEmissao';
import { mensagemFiscalReducer, mensagemFiscalSaga } from './tributario/mensagemFiscal';
import { funruralReducer, funruralSaga } from './tributario/funrural';
import { tabelaTributariaReducer, tabelaTributariaSaga } from './tributario/tabelaTributaria';

import { usuarioWhiteLabelSaga, usuarioWhiteLabelReducer } from './intranetWhiteLabel/usuarioIntranetWl';
import { perfilUsuarioWhiteLabelSaga, perfilUsuarioWhiteLabelReducer } from './intranetWhiteLabel/perfilUsuarioIntranetWl';
import { partnerWhiteLabelSaga, partnerWhiteLabelReducer } from './intranetWhiteLabel/partnerWl';
import { grupoContaAgrareWhiteLabelSaga, grupoContaAgrareWhiteLabelReducer } from './intranetWhiteLabel/grupoContaAgrareWl';
import { catalagoProdutoWhiteLabelSaga, catalagoProdutoWhiteLabelReducer } from './intranetWhiteLabel/catalagoProdutoWl';
import { dashboardCorporateWhiteLabelSaga, dashboardCorporateWhiteLabelReducer } from './intranetWhiteLabel/dashboardCorporateWl';

//Layout Imports
import { helpDrawerSaga, helpDrawerReducer } from './layout/helpDrawer';

export default function* rootSaga() {
  yield all([
    ...loginSaga,
    ...contaAgrareSaga,
    ...startSessionSaga,
    ...configGeralSaga,
    ...propriedadeCadastroSaga,
    ...perfilSaga,
    ...usuarioSaga,
    ...fazendaCadastroSaga,
    ...talhaoCadastroSaga,
    ...funcionarioCargoCadastroSaga,
    ...funcionarioCadastroSaga,
    ...unidadeMedidaCadastroSaga,
    ...unidadeMedidaConversaoCadastroSaga,
    ...patrimonioCadastroSaga,
    ...patrimonioManutencaoCadastroSaga,
    ...patrimonioAbastecimentoCadastroSaga,
    ...produtoInsumoCadastroSaga,
    ...clienteCadastroSaga,
    ...fornecedorCadastroSaga,
    ...transportadoraCadastroSaga,
    ...pluviometroDigitalSaga,
    ...motoristaCadastroSaga,
    ...veiculoCadastroSaga,
    ...identificadorCadastroSaga,
    ...mapSaga,
    ...calendarioSaga,
    ...notificationSaga,
    ...dashboardPatrimonioSaga,
    ...safraCadastroSaga,
    ...procedimentoCadastroSaga,
    ...despesaDiretaCadastroSaga,
    ...planejamentoSaga,
    ...plantioManejoSaga,
    ...colheitaSaga,
    ...vendaProducaoAgricolaSaga,
    ...relatorioEstoqueProducaoPorPeriodoSaga,
    ...implantacaoEstoqueProducaoSaga,
    ...transferenciaEstoqueProducaoSaga,
    ...estagioAplicacaoCadastroSaga,
    ...ndviSaga,
    ...baixaManualEstoqueProducaoSaga,
    ...dashboardSaga,
    ...culturaCadastroSaga,
    ...produtoAgricolaCadastroSaga,
    ...planoContaCadastroSaga,
    ...contaCaixaCadastroSaga,
    ...contaChequePreDatadoCadastroSaga,
    ...contaBancoCadastroSaga,
    ...contaCartaoCadastroSaga,
    ...controleContaCaixaSaga,
    ...controleContaBancoSaga,
    ...fluxoCaixaSaga,
    ...baixaContaSaga,
    ...contaPagarReceberAbsSaga,
    ...contaPagarSaga,
    ...contaPagarRecorrenteSaga,
    ...contaReceberSaga,
    ...dashboardFinanceiroSaga,
    ...transferenciaContaBancoCaixaSaga,
    ...localEstoqueCadastroSaga,
    ...entradaEstoqueSaga,
    ...implantacaoEstoqueSaga,
    ...baixarManualEstoqueSaga,
    ...transferenciaEstoqueSaga,
    ...relatorioEstoqueAtualSaga,
    ...relatorioReposicaoEstoqueMinimoSaga,
    ...relatorioEstoquePorPeriodoSaga,
    ...naturezaOperacaoCadastroSaga,
    ...mensagemFiscalSaga,
    ...funruralSaga,
    ...tabelaTributariaSaga,
    ...procedimentoPecuarioCadastroSaga,
    ...animalEspecieCadastroSaga,
    ...animalRacaCadastroSaga,
    ...animalPelagemCadastroSaga,
    ...animalCategoriaCadastroSaga,
    ...animalLoteCadastroSaga,
    ...animalCadastroSaga,
    ...campoCadastroSaga,
    ...anoPecuarioCadastroSaga,
    ...manejoPlanejamentoCadastroSaga,
    ...manejoExecucaoCadastroSaga,
    ...relatorioMovimentacaoAnimalSaga,
    ...evolucaoAnimalSaga,
    ...transferenciaAnimalSaga,
    ...compraAnimalSaga,
    ...vendaAnimalSaga,
    ...implantacaoAnimalSaga,
    ...dashboardPecuariaSaga,
    ...outrasSaidaAnimalSaga,
    ...emitenteCadastroSaga,
    ...certificadoDigitalCadastroSaga,
    ...monitoramentoNFeCompraSaga,
    ...nfeEmissaoSaga,
    ...usuarioWhiteLabelSaga,
    ...perfilUsuarioWhiteLabelSaga,
    ...partnerWhiteLabelSaga,
    ...grupoContaAgrareWhiteLabelSaga,
    ...catalagoProdutoWhiteLabelSaga,
    ...dashboardCorporateWhiteLabelSaga,
    //Lyout Sagas
    ...helpDrawerSaga,
  ])
}

export const rootReducer = {
  loginReducer,
  contaAgrareReducer,
  startSessionReducer,
  configGeralReducer,
  propriedadeCadastroReducer,
  perfilReducer,
  usuarioReducer,
  fazendaCadastroReducer,
  talhaoCadastroReducer,
  funcionarioCargoCadastroReducer,
  funcionarioCadastroReducer,
  unidadeMedidaCadastroReducer,
  unidadeMedidaConversaoCadastroReducer,
  patrimonioCadastroReducer,
  patrimonioManutencaoCadastroReducer,
  patrimonioAbastecimentoCadastroReducer,
  produtoInsumoCadastroReducer,
  clienteCadastroReducer,
  fornecedorCadastroReducer,
  transportadoraCadastroReducer,
  pluviometroDigitalReducer,
  motoristaCadastroReducer,
  veiculoCadastroReducer,
  identificadorCadastroReducer,
  mapReducer,
  calendarioReducer,
  notificationReducer,
  dashboardPatrimonioReducer,
  safraCadastroReducer,
  procedimentoCadastroReducer,
  despesaDiretaCadastroReducer,
  planejamentoReducer,
  plantioManejoReducer,
  colheitaReducer,
  vendaProducaoAgricolaReducer,
  relatorioEstoqueProducaoPorPeriodoReducer,
  implantacaoEstoqueProducaoReducer,
  transferenciaEstoqueProducaoReducer,
  estagioAplicacaoCadastroReducer,
  ndviReducer,
  baixaManualEstoqueProducaoReducer,
  dashboardReducer,
  culturaCadastroReducer,
  produtoAgricolaCadastroReducer,
  planoContaCadastroReducer,
  contaCaixaCadastroReducer,
  contaChequePreDatadoCadastroReducer,
  contaBancoCadastroReducer,
  contaCartaoCadastroReducer,
  controleContaCaixaReducer,
  controleContaBancoReducer,
  fluxoCaixaReducer,
  baixaContaReducer,
  contaPagarReceberAbsReducer,
  contaPagarReducer,
  contaPagarRecorrenteReducer,
  contaReceberReducer,
  dashboardFinanceiroReducer,
  transferenciaContaBancoCaixaReducer,
  localEstoqueCadastroReducer,
  entradaEstoqueReducer,
  implantacaoEstoqueReducer,
  baixarManualEstoqueReducer,
  transferenciaEstoqueReducer,
  relatorioEstoqueAtualReducer,
  relatorioReposicaoEstoqueMinimoReducer,
  relatorioEstoquePorPeriodoReducer,
  naturezaOperacaoCadastroReducer,
  mensagemFiscalReducer,
  funruralReducer,
  tabelaTributariaReducer,
  procedimentoPecuarioCadastroReducer,
  animalEspecieCadastroReducer,
  animalRacaCadastroReducer,
  animalPelagemCadastroReducer,
  animalCategoriaCadastroReducer,
  animalLoteCadastroReducer,
  animalCadastroReducer,
  campoCadastroReducer,
  anoPecuarioCadastroReducer,
  manejoPlanejamentoCadastroReducer,
  manejoExecucaoCadastroReducer,
  relatorioMovimentacaoAnimalReducer,
  evolucaoAnimalReducer,
  transferenciaAnimalReducer,
  compraAnimalReducer,
  vendaAnimalReducer,
  implantacaoAnimalReducer,
  dashboardPecuariaReducer,
  outrasSaidaAnimalReducer,
  emitenteCadastroReducer,
  certificadoDigitalCadastroReducer,
  monitoramentoNFeCompraReducer,
  nfeEmissaoReducer,
  usuarioWhiteLabelReducer,
  perfilUsuarioWhiteLabelReducer,
  partnerWhiteLabelReducer,
  grupoContaAgrareWhiteLabelReducer,
  catalagoProdutoWhiteLabelReducer,
  dashboardCorporateWhiteLabelReducer,
  //Lyout Reducers
  helpDrawerReducer,
}

export * from './login';
export * from './contaAgrare';
export * from './common/startSession';
export * from './config/geral';
export * from './common/propriedadeCadastro';
export * from './common/perfil';
export * from './common/usuario';
export * from './common/fazendaCadastro';
export * from './common/talhaoCadastro';
export * from './common/funcionarioCargoCadastro';
export * from './common/funcionarioCadastro';
export * from './common/unidadeMedidaCadastro';
export * from './common/unidadeMedidaConversaoCadastro';
export * from './common/produtoInsumoCadastro';
export * from './common/pessoaCadastro';
export * from './common/clienteCadastro';
export * from './common/fornecedorCadastro';
export * from './common/transportadoraCadastro';
export * from './common/pluviometroDigital';
export * from './common/motoristaCadastro';
export * from './common/veiculoCadastro';
export * from './common/dashboardPatrimonio';
export * from './common/identificadorCadastro';
export * from './common/map';
export * from './common/calendario';
export * from './common/notification';
export * from './common/patrimonioCadastro';
export * from './patrimonio/manutencaoCadastro';
export * from './patrimonio/abastecimentoCadastro';
export * from './agricultura/dashboard';
export * from './agricultura/safraCadastro';
export * from './agricultura/procedimentoCadastro';
export * from './agricultura/despesaDiretaCadastro';
export * from './agricultura/atividadeAgricola/planejamento';
export * from './agricultura/atividadeAgricola/plantioManejo';
export * from './agricultura/atividadeAgricola/colheita';
export * from './agricultura/culturaCadastro';
export * from './agricultura/produtoAgricolaCadastro';
export * from './agricultura/vendaProducaoAgricola';
export * from './agricultura/relatorioEstoqueProducaoPorPeriodo';
export * from './agricultura/implantacaoEstoqueProducao';
export * from './agricultura/transferenciaEstoqueProducao';
export * from './agricultura/estagioAplicacaoCadastro';
export * from './agricultura/baixarManualEstoqueProducao';
export * from './agricultura/ndvi';
export * from './financeiro/enums/';
export * from './financeiro/planoContaCadastro';
export * from './financeiro/contaCaixaCadastro';
export * from './financeiro/contaChequePreDatadoCadastro';
export * from './financeiro/contaBancoCadastro';
export * from './financeiro/contaCartaoCadastro';
export * from './financeiro/controleContaCaixa';
export * from './financeiro/controleContaBanco';
export * from './financeiro/fluxoCaixa';
export * from './financeiro/baixarConta';
export * from './financeiro/contaPagarReceberAbs';
export * from './financeiro/contaPagar';
export * from './financeiro/contaPagarRecorrente';
export * from './financeiro/contaReceber';
export * from './financeiro/dashboard';
export * from './financeiro/transferenciaContaBancoCaixa';
export * from './estoque/localEstoqueCadastro';
export * from './estoque/entradaEstoque';
export * from './estoque/implantacaoEstoque';
export * from './estoque/baixarManualEstoque';
export * from './estoque/relatorioEstoqueAtual';
export * from './estoque/relatorioReposicaoEstoqueMinimo';
export * from './estoque/relatorioEstoquePorPeriodo';
export * from './estoque/transferenciaEstoque';
export * from './tributario/naturezaOperacaoCadastro';
export * from './tributario/mensagemFiscal';
export * from './tributario/funrural';
export * from './tributario/tabelaTributaria';
export * from './pecuaria/enums/';
export * from './pecuaria/procedimentoPecuarioCadastro';
export * from './pecuaria/animalEspecieCadastro';
export * from './pecuaria/animalRacaCadastro';
export * from './pecuaria/animalPelagemCadastro';
export * from './pecuaria/animalCategoriaCadastro';
export * from './pecuaria/animalLoteCadastro';
export * from './pecuaria/animalCadastro';
export * from './pecuaria/campoCadastro';
export * from './pecuaria/anoPecuarioCadastro';
export * from './pecuaria/manejoPlanejamentoCadastro';
export * from './pecuaria/manejoExecucaoCadastro';
export * from './pecuaria/relatorioMovimentacaoAnimal';
export * from './pecuaria/evolucaoAnimal';
export * from './pecuaria/transferenciaAnimal';
export * from './pecuaria/compraAnimal';
export * from './pecuaria/vendaAnimal';
export * from './pecuaria/implantacaoAnimal';
export * from './pecuaria/dashboard';
export * from './pecuaria/outrasSaidaAnimal';
export * from './fiscal/emitenteCadastro';
export * from './fiscal/certificadoDigitalCadastro';
export * from './fiscal/monitoramentoNFeCompra';
export * from './fiscal/nfeEmissao';
export * from './intranetWhiteLabel/usuarioIntranetWl';
export * from './intranetWhiteLabel/perfilUsuarioIntranetWl';
export * from './intranetWhiteLabel/partnerWl';
export * from './intranetWhiteLabel/catalagoProdutoWl';
export * from './intranetWhiteLabel/grupoContaAgrareWl';
export * from './intranetWhiteLabel/dashboardCorporateWl';
//Layout Exports
export * from './layout/helpDrawer';
