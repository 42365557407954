import { takeLatest, put, call } from 'redux-saga/effects';

import { findCertificadoDigitalCadastro } from '../service';
import {
  FIND_CERTIFICADO_DIGITAL_CADASTRO,
  FIND_CERTIFICADO_DIGITAL_CADASTRO_PENDING,
  FIND_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS,
  FIND_CERTIFICADO_DIGITAL_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindCertificadoDigitalCadastro(action) {
  yield put({ type: FIND_CERTIFICADO_DIGITAL_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findCertificadoDigitalCadastro, action.id);

    yield put({
      type: FIND_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS, registro: {
        monitoraNfe: false,
        ...registro,
        senhaCertificadoTemp: ''
      }
    })

  } catch (error) {

    yield put({ type: FIND_CERTIFICADO_DIGITAL_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindCertificadoDigitalCadastro() {
  yield takeLatest(FIND_CERTIFICADO_DIGITAL_CADASTRO, sagaFindCertificadoDigitalCadastro)
}
