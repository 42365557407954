import watchNewNFeEmissao from './saga/new';
import watchFindNFeEmissao from './saga/find';
import watchCreateNFeEmissao from './saga/create';
import watchNewFilterNFeEmissao from './saga/newFilter';
import watchNewFilterNextPageNFeEmissao from './saga/newFilterNextPage';
import watchDeleteNFeEmissaoCadastro from './saga/delete';
import watchEmitirNFeEmissao from './saga/emitir';
import watchCancelarNFeEmissao from './saga/cancelar';
import watchCorrigirCCeNFeEmissao from './saga/corrigirCCe';
import watchDuplicarNFeEmissao from './saga/duplicar';


export const nfeEmissaoSaga = [
    watchNewNFeEmissao(),
    watchFindNFeEmissao(),
    watchCreateNFeEmissao(),
    watchNewFilterNFeEmissao(),
    watchNewFilterNextPageNFeEmissao(),
    watchDeleteNFeEmissaoCadastro(),
    watchEmitirNFeEmissao(),
    watchCancelarNFeEmissao(),
    watchCorrigirCCeNFeEmissao(),
    watchDuplicarNFeEmissao(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
