import { takeLatest, put } from 'redux-saga/effects';

import {
  certificadoDigitalCadastro,
  NEW_CERTIFICADO_DIGITAL_CADASTRO,
  NEW_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewCertificadoDigitalCadastro(action) {
  yield put({
    type: NEW_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS, registro: {
      ...certificadoDigitalCadastro.newRegistro,
      estado: null,
      certificado: null,
      senhaCertificadoTemp: '',
      monitoraNfe: false,
      status: true,
    },
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewCertificadoDigitalCadastro() {
  yield takeLatest(NEW_CERTIFICADO_DIGITAL_CADASTRO, sagaNewCertificadoDigitalCadastro)
}
