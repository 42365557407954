import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_TABELA_TRIBUTARIA_RAPIDO,
    CANCEL_TABELA_TRIBUTARIA_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelTabelaTributariaRapido() {
  yield put({ type: CANCEL_TABELA_TRIBUTARIA_RAPIDO_SUCCESS})
}

export default function* watchCancelTabelaTributariaRapido() {
  yield takeLatest(CANCEL_TABELA_TRIBUTARIA_RAPIDO, sagaCancelTabelaTributariaRapido)
}