import React from 'react';

import {
    Col,
    FormGroup,
    Label,
    FormFeedback,
    FormText,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';

import { Field } from 'formik';

import MoneyInput from "@rschpdr/react-money-input";
import { InfoTooltip } from './InfoTooltip';

export const InputMoney = (props) => {

    const { col, name, label, placeholder, plusChange, suffix, disabled, renderInfoTooltip, titleInfoTooltip, msgInfoTooltip, idTooltip } = props;

    const currencyConfig = {
        locale: "pt-BR",
        currencyCode: 'BRL',
        useGrouping: true
    };

    return (
        <Col md={col}>

            <FormGroup>
                <Label htmlFor={'id_' + name} >{label} {" "}
                    {
                        renderInfoTooltip ?
                            <InfoTooltip
                                id={idTooltip === undefined || idTooltip === null ? 'idtool_' + name.split('.').shift() : idTooltip}
                                title={titleInfoTooltip}
                                msg={msgInfoTooltip}
                            />
                            : <React.Fragment />
                    }</Label>
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <>
                            <InputGroup>
                                <MoneyInput
                                    {...field}
                                    name={name}
                                    value={field.value ?? 0}
                                    placeholder={placeholder}
                                    id={'id_' + name}
                                    className='form-control'
                                    disabled={disabled}
                                    autoComplete="off"

                                    currencyConfig={currencyConfig}
                                    style={{
                                        borderColor: meta.error && meta.touched ? 'red' : 'none',
                                        backgroundColor: disabled ? '#e9ecef' : 'white',
                                        cursor: disabled ? 'not-allowed' : 'text'
                                    }}
                                    onChange={(e) => {
                                        form.handleChange(e);
                                        plusChange(e);
                                    }}
                                />
                                <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
                                <FormFeedback>{meta.error}</FormFeedback>
                            </InputGroup>
                            {meta.error && meta.touched ? <FormText color='danger'>{meta.error}</FormText> : <React.Fragment />}
                        </>
                    )}
                </Field>
            </FormGroup>

        </Col>
    )
}

InputMoney.defaultProps = {
    col: 12,
    disabled: false,
    renderInfoTooltip: false,
    titleInfoTooltip: 'Informação',
    plusChange: () => { },
};