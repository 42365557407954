import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    ativo: true,
    tipoOperacao: null,
    geraDuplicata: true,
    movimentaFinanceiro: true,
    lancaProduto: true,
    movimentaEstoque: true,
    contaGerencial: null,
    historico: '',
    configurarNaturezaFiscal: false,
    nfeFinalidadeEmissao: null,
    nfeIndicadorOperacaoConsumidor: null,
    nfeConsumidorPresenca: null,
    nfeNaturezaExigeCfopEspecifica: false,
    nfeCfopEstadual: null,
    nfeCfopInterestadual: null,
    nfeCfopExportacao: null,
    nfeMensagemFiscal: null,
    nfeTabelaTributaria: null,
    nfeExigeDocRef: false,
    nfePermiteEdicaoImpostos: false,
    nfeExigeCnd: false,
    nfeCalculaIcms: false,
    nfeCalculaIcmsSt: false,
    nfeCalculaIpi: false,
    nfeCalculaPis: false,
    nfeCalculaPisSt: false,
    nfeCalculaCofins: false,
    nfeCalculaCofinsSt: false,
    nfeCalculaIcmsDesonerado: false,
    nfeCalculaIcmsStDesonerado: false,
    nfeDescontaIcmsDesoneradoDoTotal: false,
    nfeCalculaFunrural: false,
    nfeDestacarIcmsDanfe: false,
    nfeIncluiIpiCalculoICMS: false,
    nfeNaoInformarCbenef: false,
    nfeUtilizarCbenefDaOperacao: false,
    nfeCbenef: '',
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    tipoOperacao: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    historico: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres'),
    nfeFinalidadeEmissao: Yup.string().when('configurarNaturezaFiscal', {
        is: (configurarNaturezaFiscal) => configurarNaturezaFiscal === true,
        then: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório')
    }).nullable(),
    nfeIndicadorOperacaoConsumidor: Yup.string().when('configurarNaturezaFiscal', {
        is: (configurarNaturezaFiscal) => configurarNaturezaFiscal === true,
        then: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório')
    }).nullable(),
    nfeConsumidorPresenca: Yup.string().when('configurarNaturezaFiscal', {
        is: (configurarNaturezaFiscal) => configurarNaturezaFiscal === true,
        then: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório')
    }).nullable(),
    nfeCfopEstadual: Yup.object().when(['configurarNaturezaFiscal', 'nfeNaturezaExigeCfopEspecifica'], {
        is: (configurarNaturezaFiscal, nfeNaturezaExigeCfopEspecifica) => configurarNaturezaFiscal === true && nfeNaturezaExigeCfopEspecifica === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    nfeCfopInterestadual: Yup.object().when(['configurarNaturezaFiscal', 'nfeNaturezaExigeCfopEspecifica'], {
        is: (configurarNaturezaFiscal, nfeNaturezaExigeCfopEspecifica) => configurarNaturezaFiscal === true && nfeNaturezaExigeCfopEspecifica === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    nfeCbenef: Yup.string()
        .min(10, 'CBENEF deve ter 10 caracteres')
        .max(10, 'CBENEF deve ter 10 caracteres'),
});

export const naturezaOperacaoCadastro = {
    newRegistro,
    validation
}