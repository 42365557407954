import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    apelido: '',
    status: true,
    tipoPessoa: 'FISICA',
    cpf: '',
    dataNascimento: '',
    cnpj: '',
    indicadorInscrEstadual: 'NAOCONTRIBUINTE',
    inscricaoEstadual: '',
    email: '',
    celular: '',
    telefone: '',
    endereco: {
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        estado: null,
        cidade: null,
    },
    nomeSocioResponsavel: '',
    cpfSocioResponsavel: '',
    emiteNfe: false,
    codigoRegimeTributario: '',
    formaContribuicaoFunrural: '',
    observacaoFunrural: '',
    mensagemFiscal: null,
    emitenteSerieNfe: {
        serieNfe: null,
        proximoNroNfe: null,
        serieNfeHomolog: null,
        proximoNroNfeHomolog: null,
    },
    emitenteCnd: {
        informarCnd: false,
        numeroCnd: '',
        dataValidadeCnd: null
    }
};

const validation = Yup.object().shape({
    tipoPessoa: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    indicadorInscrEstadual: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    email: Yup.string()
        .email('E-mail inválido')
        .max(150, 'Campo deve ter menos do que 150 caracteres'),
    nomeSocioResponsavel: Yup.string().when('tipoPessoa', {
        is: (tipoPessoa) => tipoPessoa === 'JURIDICA',
        then: Yup.string()
            .min(2, 'O campo deve ter no mínimo 2 caracteres')
            .max(60, 'Campo deve ter menos do que 60 caracteres')
            .required('Este campo é obrigatório'),
    }).nullable(),
    endereco: Yup.object().shape({
        logradouro: Yup.string()
            .min(2, 'O campo deve ter no mínimo 2 caracteres!')
            .max(60, 'Campo deve ter menos do que 60 caracteres!')
            .required('Este campo é obrigatório'),
        numero: Yup.string()
            .min(2, 'O campo deve ter no mínimo 2 caracteres!')
            .max(60, 'Campo deve ter menos do que 60 caracteres!')
            .required('Este campo é obrigatório'),
        complemento: Yup.string()
            .max(60, 'Campo deve ter menos do que 60 caracteres!'),
        bairro: Yup.string()
            .min(2, 'O campo deve ter no mínimo 2 caracteres!')
            .max(60, 'Campo deve ter menos do que 60 caracteres!')
            .required('Este campo é obrigatório'),
        cep: Yup.string()
            .required('Este campo é obrigatório'),
        estado: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
        cidade: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
    }),
    codigoRegimeTributario: Yup.string().when('emiteNfe', {
        is: true,
        then: Yup.string().required('Este campo é obrigatório'),
    }),
    formaContribuicaoFunrural: Yup.string().when('emiteNfe', {
        is: true,
        then: Yup.string().required('Este campo é obrigatório'),
    }),
    observacaoFunrural: Yup.string().max(500, 'Campo deve ter menos do que 500 caracteres'),
    emitenteSerieNfe: Yup.object().when('emiteNfe', {
        is: true,
        then: Yup.object().shape({
            serieNfe: Yup.number()
                .typeError('Este campo deve ser um número')
                .required('Este campo é obrigatório'),
            proximoNroNfe: Yup.number()
                .typeError('Este campo deve ser um número')
                .min(1, 'O número deve estar entre 1 e 999999999')
                .max(999999999, 'O número deve estar entre 1 e 999999999')
                .required('Este campo é obrigatório'),
        }),
    }),
    emitenteCnd: Yup.object().shape({
        numeroCnd: Yup.string()
            .max(100, 'Campo deve ter menos do que 100 caracteres'),
    }),
});

export const emitenteCadastro = {
    newRegistro,
    validation
};
