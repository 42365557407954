import watchNewTabelaTributaria from './saga/new';
import watchFindTabelaTributaria from './saga/find';
import watchCreateTabelaTributaria from './saga/create';
import watchListAllTabelaTributaria from './saga/listAll';
import watchListTabelaTributaria from './saga/listTable';
import watchDeleteTabelaTributaria from './saga/delete';
import watchCancelTabelaTributariaRapido from './saga/cancel';

export const tabelaTributariaSaga = [
    watchNewTabelaTributaria(),
    watchFindTabelaTributaria(),
    watchCreateTabelaTributaria(),
    watchListAllTabelaTributaria(),
    watchListTabelaTributaria(),
    watchDeleteTabelaTributaria(),
    watchCancelTabelaTributariaRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
