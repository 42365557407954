import watchFindFunrural from './saga/find';
import watchCreateFunrural from './saga/create';

export const funruralSaga = [
    watchFindFunrural(),
    watchCreateFunrural(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
