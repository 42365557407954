import { takeLatest, put } from 'redux-saga/effects';

import {
  emitenteCadastro,
  NEW_EMITENTE_CADASTRO,
  NEW_EMITENTE_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewEmitenteCadastro(action) {
  yield put({
    type: NEW_EMITENTE_CADASTRO_SUCCESS, registro: {
      ...emitenteCadastro.newRegistro,
      endereco: {
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        estado: null,
        cidade: null,
      },
      nomeSocioResponsavel: '',
      cpfSocioResponsavel: '',
      emiteNfe: false,
      codigoRegimeTributario: '',
      formaContribuicaoFunrural: '',
      observacaoFunrural: '',
      emitenteSerieNfe: {
          serieNfe: null,
          proximoNroNfe: null
      },
      emitenteCnd: {
          informarCnd: false,
          numeroCnd: '',
          dataValidadeCnd: null
      }
    },
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewEmitenteCadastro() {
  yield takeLatest(NEW_EMITENTE_CADASTRO, sagaNewEmitenteCadastro)
}
