import watchNewCertificadoDigitalCadastro from './saga/new';
import watchFindCertificadoDigitalCadastro from './saga/find';
import watchCreateCertificadoDigitalCadastro from './saga/create';
import watchListAllCertificadoDigitalCadastro from './saga/listAll';
import watchListCertificadoDigitalCadastro from './saga/listTable';
import watchDeleteCertificadoDigitalCadastro from './saga/delete';
import watchCancelCertificadoDigitalCadastroRapido from './saga/cancel';

export const certificadoDigitalCadastroSaga = [
    watchNewCertificadoDigitalCadastro(),
    watchFindCertificadoDigitalCadastro(),
    watchCreateCertificadoDigitalCadastro(),
    watchListAllCertificadoDigitalCadastro(),
    watchListCertificadoDigitalCadastro(),
    watchDeleteCertificadoDigitalCadastro(),
    watchCancelCertificadoDigitalCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
