import * as Yup from 'yup';

const newRegistro = {
    pessoaFisicaInss: 0,
    pessoaFisicaRat: 0,
    pessoaFisicaSenar: 0,
    pessoaFisicaTotalFunrural: 0,
    pessoaJuridicaInss: 0,
    pessoaJuridicaRat: 0,
    pessoaJuridicaSenar: 0,
    pessoaJuridicaTotalFunrural: 0,
};

const validation = Yup.object().shape({
    pessoaFisicaInss: Yup.number()
        .required('INSS da Pessoa Física é obrigatório.')
        .min(0, 'O valor deve ser maior ou igual a 0.')
        .typeError('O valor deve ser um número válido.'),
    pessoaFisicaRat: Yup.number()
        .required('RAT da Pessoa Física é obrigatório.')
        .min(0, 'O valor deve ser maior ou igual a 0.')
        .typeError('O valor deve ser um número válido.'),
    pessoaFisicaSenar: Yup.number()
        .required('SENAR da Pessoa Física é obrigatório.')
        .min(0, 'O valor deve ser maior ou igual a 0.')
        .typeError('O valor deve ser um número válido.'),
    pessoaJuridicaInss: Yup.number()
        .required('INSS da Pessoa Jurídica é obrigatório.')
        .min(0, 'O valor deve ser maior ou igual a 0.')
        .typeError('O valor deve ser um número válido.'),
    pessoaJuridicaRat: Yup.number()
        .required('RAT da Pessoa Jurídica é obrigatório.')
        .min(0, 'O valor deve ser maior ou igual a 0.')
        .typeError('O valor deve ser um número válido.'),
    pessoaJuridicaSenar: Yup.number()
        .required('SENAR da Pessoa Jurídica é obrigatório.')
        .min(0, 'O valor deve ser maior ou igual a 0.')
        .typeError('O valor deve ser um número válido.'),
});

export const funrural = {
    newRegistro,
    validation,
};
