import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    formulacao: '',
    fabricante: '',
    observacao: '',
    status: true,
    categoria: null,
    grupo: null,
    dosagemHa: 0,
    unidadeCompra: null,
    unidadeAplicacao: null,
    controlaEstoque: true,
    unidadeEstoque: null,
    estoqueMinimo: 0,
    custo: 0,
    cultura: null,
    produtoInsumoEstoques: [],
    produtoInsumoFornecedores: [],
    configLancAgricultura: false,
    configLancAgriculturaTipo: null,
    importadoCatalago: false,
    unidadeNFe: null,
    dadosFiscaisItemNfe: {
        informarDadosNfe: false,
        nomeItemNF: '',
        ncm: '',
        ncmEx: '',
        cest: '',
        gtinEan: '',
        cbenef: '',
        unidadeTributavel: null,
        gtinEanTributavel: '',
        cfopEstadual: null,
        cfopInterestadual: null,
        cfopExportacao: null,
        tabelaTributaria: null,
    },
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),
    formulacao: Yup.string()
        .max(250, 'Campo deve ter menos do que 250 caracteres'),
    fabricante: Yup.string()
        .max(120, 'Campo deve ter menos do que 120 caracteres'),
    observacao: Yup.string()
        .max(5000, 'Campo deve ter menos do que 5000 caracteres'),
    categoria: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    grupo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidadeCompra: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    unidadeAplicacao: Yup.object().when('categoria', {
        is: (categoria) => categoria === 'DEFENSIVO' || categoria === 'FERTILIZANTE'
            || categoria === 'CULTIVAR' || categoria === 'COMBUSTIVEL',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    unidadeEstoque: Yup.object().when('controlaEstoque', {
        is: (controlaEstoque) => controlaEstoque === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    estoqueMinimo: Yup.number().when('controlaEstoque', {
        is: (controlaEstoque) => controlaEstoque === true,
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .min(0, 'Por Favor, informe um valor válido'),
    }),
    custo: Yup.number()
        .typeError('Este campo é obrigatório')
        .min(0, 'Por Favor, informe um valor válido'),
    // CAMPO DOSAGEM
    dosagemHa: Yup.number().when('categoria', {
        is: (categoria) => (
            categoria === 'DEFENSIVO'
            || categoria === 'FERTILIZANTE'),
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .min(0, 'Por Favor, informe um valor válido'),
    }),
    // SEMENTE E MUDA
    cultura: Yup.object().when('categoria', {
        is: (categoria) => categoria === 'CULTIVAR',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    produtoInsumoFornecedores: Yup.array()
        .of(
            Yup.object().shape({
                fornecedor: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                codigoProdutoFornecedor: Yup.string()
                    .max(50, 'Campo deve ter menos do que 50 caracteres!'),
            })
        ),

    unidadeNFe: Yup.object().when('dadosFiscaisItemNfe.informarDadosNfe', {
        is: (informarDadosNfe) => informarDadosNfe === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),

    dadosFiscaisItemNfe: Yup.object().shape({
        informarDadosNfe: Yup.boolean(),

        // Validação condicional para NCM
        nomeItemNF: Yup.string()
            .when('informarDadosNfe', {
                is: true,
                then: Yup.string()
                    .min(2, 'Nome deve ter mínomo 2 caracteres')
                    .max(120, 'Nome deve ter máximo 120 caracteres')
                    .required('Nome é obrigatório'),
                otherwise: Yup.string(),
            }),
        ncm: Yup.string()
            .when('informarDadosNfe', {
                is: true,
                then: Yup.string()
                    .min(8, 'NCM deve ter 8 caracteres')
                    .max(8, 'NCM deve ter 8 caracteres')
                    .required('NCM é obrigatório'),
                otherwise: Yup.string(),
            }),
        ncmEx: Yup.string()
            .when('informarDadosNfe', {
                is: true,
                then: Yup.string()
                    .max(3, 'Campo deve ter menos do que 3 caracteres'),
                otherwise: Yup.string(),
            }),
        cest: Yup.string()
            .when('informarDadosNfe', {
                is: true,
                then: Yup.string()
                    .max(8, 'Campo deve ter menos do que 8 caracteres'),
                otherwise: Yup.string(),
            }),
        gtinEan: Yup.string()
            .when('informarDadosNfe', {
                is: true,
                then: Yup.string()
                    .max(14, 'Campo deve ter menos do que 14 caracteres'),
                otherwise: Yup.string(),
            }),
        gtinEanTributavel: Yup.string()
            .when('informarDadosNfe', {
                is: true,
                then: Yup.string()
                    .max(14, 'Campo deve ter menos do que 14 caracteres'),
                otherwise: Yup.string(),
            }),
        cbenef: Yup.string()
            .when('informarDadosNfe', {
                is: true,
                then: Yup.string()
                    // .min(10, 'CBENEF deve ter 10 caracteres')
                    .max(10, 'CBENEF deve ter 10 caracteres'),
                otherwise: Yup.string(),
            }),

        // Validação condicional para CFOP Estadual
        cfopEstadual: Yup.object().when('informarDadosNfe', {
            is: (informarDadosNfe) => informarDadosNfe === true,
            then: Yup.object()
                .typeError('Este campo é obrigatório')
                .shape({
                    id: Yup.number()
                        .typeError('Este campo é obrigatório')
                        .required("Este campo é obrigatório"),
                })
        }).nullable(),

        // Validação condicional para CFOP Interestadual
        cfopInterestadual: Yup.object().when('informarDadosNfe', {
            is: (informarDadosNfe) => informarDadosNfe === true,
            then: Yup.object()
                .typeError('Este campo é obrigatório')
                .shape({
                    id: Yup.number()
                        .typeError('Este campo é obrigatório')
                        .required("Este campo é obrigatório"),
                })
        }).nullable(),

        // Validação condicional para CFOP Exportação
        // cfopExportacao: Yup.object()
        //     .nullable()
        //     .when('informarDadosNfe', {
        //         is: true,
        //         then: Yup.object()
        //             .shape({
        //                 id: Yup.number()
        //                     .typeError('CFOP Exportação é obrigatório')
        //                     .required('CFOP Exportação é obrigatório'),
        //             })
        //             .required('CFOP Exportação é obrigatório'),
        //         otherwise: Yup.object().nullable(),
        //     }),

        // Validação condicional para Tabela Tributária
        tabelaTributaria: Yup.object().when('informarDadosNfe', {
            is: (informarDadosNfe) => informarDadosNfe === true,
            then: Yup.object()
                .typeError('Este campo é obrigatório')
                .shape({
                    id: Yup.number()
                        .typeError('Este campo é obrigatório')
                        .required("Este campo é obrigatório"),
                })
        }).nullable(),
    }),
});

export const produtoInsumoCadastro = {
    newRegistro,
    validation
}