import { takeLatest, put, call } from 'redux-saga/effects';

import {
  CANCELAR_NFE_EMISSAO,
  CANCELAR_NFE_EMISSAO_PENDING,
  CANCELAR_NFE_EMISSAO_SUCCESS,
  CANCELAR_NFE_EMISSAO_FAILURE
} from '../actionTypes';
import { AlertError, AlertSuccess, AlertWarning } from '@components/common/AlertToast';
import { cancelarNFeEmissao, findNFeEmissao } from '../service';

function* sagaCancelarNFeEmissao(action) {
  yield put({ type: CANCELAR_NFE_EMISSAO_PENDING })

  try {
    yield call(cancelarNFeEmissao, action.idNota, action.justificativa);

    const registro = yield call(findNFeEmissao, action.idNota);

    yield put({ type: CANCELAR_NFE_EMISSAO_SUCCESS, registro: registro })

    AlertSuccess('NFe cancelada com sucesso!');
  } catch (error) {
    if (error.response.status === 400) {
      AlertWarning(error.response.data);
    } else {
      AlertError('Falha ao cancelar a NFe! Verifique o prazo e as demais regras de cancelamento da NFe.');
    }
    yield put({ type: CANCELAR_NFE_EMISSAO_FAILURE })
  }
}

export default function* watchCancelarNFeEmissao() {
  yield takeLatest(CANCELAR_NFE_EMISSAO, sagaCancelarNFeEmissao)
}
