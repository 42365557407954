import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createTransportadoraCadastro, updateTransportadoraCadastro } from '../service';
import {
  CREATE_TRANSPORTADORA_CADASTRO,
  CREATE_TRANSPORTADORA_CADASTRO_PENDING,
  CREATE_TRANSPORTADORA_CADASTRO_SUCCESS,
  CREATE_TRANSPORTADORA_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateTransportadoraCadastro(action) {
  yield put({ type: CREATE_TRANSPORTADORA_CADASTRO_PENDING })

  try {
    let type = '';
    if (action.data.tipoPessoa === 'FISICA') {
      type = 'PessoaFisica';
      if (action.data.indicadorInscrEstadual === null || action.data.indicadorInscrEstadual === undefined || action.data.indicadorInscrEstadual === '') {
        action.data.indicadorInscrEstadual = 'NAOCONTRIBUINTE';
      }
    } else {
      type = 'PessoaJuridica';
      if (action.data.indicadorInscrEstadual === null || action.data.indicadorInscrEstadual === undefined || action.data.indicadorInscrEstadual === '') {
        action.data.indicadorInscrEstadual = 'CONTRIBUINTEISENTO';
      }
      if (action.data.indicadorInscrEstadual = 'NAOCONTRIBUINTE'){
        action.data.inscricaoEstadual = null;
      }
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createTransportadoraCadastro, formatJsonRemoveEmptyOrNull({
        ...action.data,
        type: type
      }));
    } else {
      yield call(updateTransportadoraCadastro,
        formatJsonRemoveEmptyOrNull({
          ...action.data,
          id: action.data.id,
          type: type
        })
      );
    }

    yield put({ type: CREATE_TRANSPORTADORA_CADASTRO_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/transportadoraCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_TRANSPORTADORA_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateTransportadoraCadastro() {
  yield takeLatest(CREATE_TRANSPORTADORA_CADASTRO, sagaCreateTransportadoraCadastro)
}
