import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllMensagemFiscal } from '../service';
import {
  LIST_MENSAGEM_FISCAL,
  LIST_MENSAGEM_FISCAL_PENDING,
  LIST_MENSAGEM_FISCAL_SUCCESS,
  LIST_MENSAGEM_FISCAL_FAILURE
} from '../actionTypes';

function* sagaListAllMensagemFiscal(action) {
  yield put({ type: LIST_MENSAGEM_FISCAL_PENDING })

  try {
    const list = yield call(findAllMensagemFiscal)
    
    yield put({ type: LIST_MENSAGEM_FISCAL_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_MENSAGEM_FISCAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllMensagemFiscal() {
  yield takeLatest(LIST_MENSAGEM_FISCAL, sagaListAllMensagemFiscal)
}