
export const NEW_TABELA_TRIBUTARIA = 'NEW_TABELA_TRIBUTARIA'
export const NEW_TABELA_TRIBUTARIA_SUCCESS = 'NEW_TABELA_TRIBUTARIA_SUCCESS'

export const CREATE_TABELA_TRIBUTARIA = 'CREATE_TABELA_TRIBUTARIA'
export const CREATE_TABELA_TRIBUTARIA_PENDING = 'CREATE_TABELA_TRIBUTARIA_PENDING'
export const CREATE_TABELA_TRIBUTARIA_SUCCESS = 'CREATE_TABELA_TRIBUTARIA_SUCCESS'
export const CREATE_TABELA_TRIBUTARIA_FAILURE = 'CREATE_TABELA_TRIBUTARIA_FAILURE'

export const CANCEL_TABELA_TRIBUTARIA_RAPIDO = 'CANCEL_TABELA_TRIBUTARIA_RAPIDO'
export const CANCEL_TABELA_TRIBUTARIA_RAPIDO_SUCCESS = 'CANCEL_TABELA_TRIBUTARIA_RAPIDO_SUCCESS'

export const TABLE_TABELA_TRIBUTARIA = 'TABLE_TABELA_TRIBUTARIA'
export const TABLE_TABELA_TRIBUTARIA_PENDING = 'TABLE_TABELA_TRIBUTARIA_PENDING'
export const TABLE_TABELA_TRIBUTARIA_SUCCESS = 'TABLE_TABELA_TRIBUTARIA_SUCCESS'
export const TABLE_TABELA_TRIBUTARIA_FAILURE = 'TABLE_TABELA_TRIBUTARIA_FAILURE'

export const LIST_TABELA_TRIBUTARIA = 'LIST_TABELA_TRIBUTARIA'
export const LIST_TABELA_TRIBUTARIA_PENDING = 'LIST_TABELA_TRIBUTARIA_PENDING'
export const LIST_TABELA_TRIBUTARIA_SUCCESS = 'LIST_TABELA_TRIBUTARIA_SUCCESS'
export const LIST_TABELA_TRIBUTARIA_FAILURE = 'LIST_TABELA_TRIBUTARIA_FAILURE'

export const FIND_TABELA_TRIBUTARIA = 'FIND_TABELA_TRIBUTARIA'
export const FIND_TABELA_TRIBUTARIA_PENDING = 'FIND_TABELA_TRIBUTARIA_PENDING'
export const FIND_TABELA_TRIBUTARIA_SUCCESS = 'FIND_TABELA_TRIBUTARIA_SUCCESS'
export const FIND_TABELA_TRIBUTARIA_FAILURE = 'FIND_TABELA_TRIBUTARIA_FAILURE'

export const DELETE_TABELA_TRIBUTARIA = 'DELETE_TABELA_TRIBUTARIA'
export const DELETE_TABELA_TRIBUTARIA_PENDING = 'DELETE_TABELA_TRIBUTARIA_PENDING'
export const DELETE_TABELA_TRIBUTARIA_SUCCESS = 'DELETE_TABELA_TRIBUTARIA_SUCCESS'
export const DELETE_TABELA_TRIBUTARIA_FAILURE = 'DELETE_TABELA_TRIBUTARIA_FAILURE'
