import { takeLatest, put, call } from 'redux-saga/effects';

import { findTabelaTributaria } from '../service';
import {
  FIND_TABELA_TRIBUTARIA,
  FIND_TABELA_TRIBUTARIA_PENDING,
  FIND_TABELA_TRIBUTARIA_SUCCESS,
  FIND_TABELA_TRIBUTARIA_FAILURE
} from '../actionTypes';

function* sagaFindTabelaTributaria(action) {
  yield put({ type: FIND_TABELA_TRIBUTARIA_PENDING, id: action.id })

  try {

    const registro = yield call(findTabelaTributaria, action.id);

    yield put({ type: FIND_TABELA_TRIBUTARIA_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_TABELA_TRIBUTARIA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindTabelaTributaria() {
  yield takeLatest(FIND_TABELA_TRIBUTARIA, sagaFindTabelaTributaria)
}
