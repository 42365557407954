import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fiscal/tabelaTributaria' };
/////////////////////////////////////////////////////////////////////////////////

export function createTabelaTributaria(payload) {
  return httpService.post(args, payload);
}

export function updateTabelaTributaria(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listTabelaTributaria(param) {
  return httpService.get({...args, args: param});
}

export function findTabelaTributaria(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllTabelaTributaria() {
  return httpService.get({ ...args, path: '/fiscal/tabelaTributaria/all' });
}

export function deleteTabelaTributaria(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function listSituacaoTributariaIcms(param) {
  return httpService.get({...args, path: `/fiscal/st/icms/${param}`});
}

export function listSituacaoTributariaIpi(param) {
  return httpService.get({...args, path: `/fiscal/st/ipi/${param}`});
}

export function listSituacaoTributariaPis(param) {
  return httpService.get({...args, path: `/fiscal/st/pis/${param}`});
}

export function listSituacaoTributariaCofins(param) {
  return httpService.get({...args, path: `/fiscal/st/cofins/${param}`});
}
