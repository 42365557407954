import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createMensagemFiscal, updateMensagemFiscal } from '../service';
import {
  CREATE_MENSAGEM_FISCAL,
  CREATE_MENSAGEM_FISCAL_PENDING,
  CREATE_MENSAGEM_FISCAL_SUCCESS,
  CREATE_MENSAGEM_FISCAL_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateMensagemFiscal(action) {
  yield put({ type: CREATE_MENSAGEM_FISCAL_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createMensagemFiscal, {
        ...action.data
      });
    } else {
      yield call(updateMensagemFiscal,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_MENSAGEM_FISCAL_SUCCESS, registro: action.data })

    if(!action.isCadastroRapido){
      history.push('/fiscal/mensagemFiscal');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_MENSAGEM_FISCAL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateMensagemFiscal() {
  yield takeLatest(CREATE_MENSAGEM_FISCAL, sagaCreateMensagemFiscal)
}
