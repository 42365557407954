import { takeLatest, put, call } from 'redux-saga/effects';

import { findNaturezaOperacaoCadastro } from '../service';
import {
  FIND_NATUREZA_OPERACAO_CADASTRO,
  FIND_NATUREZA_OPERACAO_CADASTRO_PENDING,
  FIND_NATUREZA_OPERACAO_CADASTRO_SUCCESS,
  FIND_NATUREZA_OPERACAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindNaturezaOperacaoCadastro(action) {
  yield put({ type: FIND_NATUREZA_OPERACAO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findNaturezaOperacaoCadastro, action.id);

    yield put({
      type: FIND_NATUREZA_OPERACAO_CADASTRO_SUCCESS, registro: {
        configurarNaturezaFiscal: false,
        nfeFinalidadeEmissao: null,
        nfeIndicadorOperacaoConsumidor: null,
        nfeConsumidorPresenca: null,
        nfeNaturezaExigeCfopEspecifica: false,
        nfeCfopEstadual: null,
        nfeCfopInterestadual: null,
        nfeCfopExportacao: null,
        nfeMensagemFiscal: null,
        nfeTabelaTributaria: null,
        nfeExigeDocRef: false,
        nfePermiteEdicaoImpostos: false,
        ...registro
      }
    })

  } catch (error) {

    yield put({ type: FIND_NATUREZA_OPERACAO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindNaturezaOperacaoCadastro() {
  yield takeLatest(FIND_NATUREZA_OPERACAO_CADASTRO, sagaFindNaturezaOperacaoCadastro)
}
