import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fiscal/nfe/emissao' };
/////////////////////////////////////////////////////////////////////////////////

export function createNFeEmissao(payload) {
  return httpService.post(args, payload);
}

export function updateNFeEmissao(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findNFeEmissao(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function filterNFeEmissao(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function deleteNFeEmissao(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function calcularTotaisNFe(nota) {
  return httpService.post({ ...args, args: `/calcularTotais` }, nota);
}

export function emitirNFeEmissao(id) {
  return httpService.post({ ...args, args: `/emitir/${id}` });
}

export function cancelarNFeEmissao(id, justificativa) {
  return httpService.postPlainText({ ...args, args: `/cancelar/${id}` }, justificativa);
}

export function corrigirCCeNFeEmissao(id, correcao) {
  return httpService.postPlainText({ ...args, args: `/cce/${id}` }, correcao);
}

export function duplicarNFeEmissao(id) {
  return httpService.get({ ...args, args: `/duplicar/${id}` });
}
