import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_NFE_EMISSAO_SUCCESS,
    FIND_NFE_EMISSAO_PENDING,
    FIND_NFE_EMISSAO_SUCCESS,
    FIND_NFE_EMISSAO_FAILURE,
    CREATE_NFE_EMISSAO_PENDING,
    CREATE_NFE_EMISSAO_SUCCESS,
    CREATE_NFE_EMISSAO_FAILURE,
    NEW_FILTER_NFE_EMISSAO_PEDING,
    NEW_FILTER_NFE_EMISSAO_SUCCESS,
    NEW_FILTER_NFE_EMISSAO_FAILURE,
    NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_PENDING,
    NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_SUCCESS,
    NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_FAILURE,
    DELETE_NFE_EMISSAO_PENDING,
    DELETE_NFE_EMISSAO_SUCCESS,
    DELETE_NFE_EMISSAO_FAILURE,
    EMITIR_NFE_EMISSAO_PENDING,
    EMITIR_NFE_EMISSAO_SUCCESS,
    EMITIR_NFE_EMISSAO_FAILURE,
    CANCELAR_NFE_EMISSAO_PENDING,
    CANCELAR_NFE_EMISSAO_SUCCESS,
    CANCELAR_NFE_EMISSAO_FAILURE,
    CORRIGIR_CCE_NFE_EMISSAO_PENDING,
    CORRIGIR_CCE_NFE_EMISSAO_SUCCESS,
    CORRIGIR_CCE_NFE_EMISSAO_FAILURE,
    DUPLICAR_NFE_EMISSAO_PENDING,
    DUPLICAR_NFE_EMISSAO_SUCCESS,
    DUPLICAR_NFE_EMISSAO_FAILURE,
} from './actionTypes';

const initialState = {
    list: null,
    loading: false,
    loadingEmitindoNfe: false,
    loadingCancelamentoNfe: false,
    loadingCorrigindoNfe: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}

function nfeEmissaoReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_NFE_EMISSAO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DUPLICAR_NFE_EMISSAO_PENDING:
        case FIND_NFE_EMISSAO_PENDING:
        case CREATE_NFE_EMISSAO_PENDING:
        case DELETE_NFE_EMISSAO_PENDING:
        case NEW_FILTER_NFE_EMISSAO_PEDING:
        case NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_NFE_EMISSAO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_NFE_EMISSAO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DUPLICAR_NFE_EMISSAO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case DUPLICAR_NFE_EMISSAO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NFE_EMISSAO_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_NFE_EMISSAO_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_NFE_EMISSAO_SUCCESS:
        case CREATE_NFE_EMISSAO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_NFE_EMISSAO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case DELETE_NFE_EMISSAO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case EMITIR_NFE_EMISSAO_SUCCESS: {
            return {
                ...state,
                loadingEmitindoNfe: false,
                registro: action.registro
            }
        }
        case EMITIR_NFE_EMISSAO_PENDING: {
            return {
                ...state,
                loadingEmitindoNfe: true
            }
        }
        case EMITIR_NFE_EMISSAO_FAILURE: {
            return {
                ...state,
                registro: action.registro,
                loadingEmitindoNfe: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CANCELAR_NFE_EMISSAO_SUCCESS: {
            return {
                ...state,
                loadingCancelamentoNfe: false,
                registro: action.registro
            }
        }
        case CANCELAR_NFE_EMISSAO_PENDING: {
            return {
                ...state,
                loadingCancelamentoNfe: true
            }
        }
        case CANCELAR_NFE_EMISSAO_FAILURE: {
            return {
                ...state,
                registro: action.registro,
                loadingCancelamentoNfe: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CORRIGIR_CCE_NFE_EMISSAO_SUCCESS: {
            return {
                ...state,
                loadingCorrigindoNfe: false,
                registro: action.registro
            }
        }
        case CORRIGIR_CCE_NFE_EMISSAO_PENDING: {
            return {
                ...state,
                loadingCorrigindoNfe: true
            }
        }
        case CORRIGIR_CCE_NFE_EMISSAO_FAILURE: {
            return {
                ...state,
                registro: action.registro,
                loadingCorrigindoNfe: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { nfeEmissaoReducer };