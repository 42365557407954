import { takeLatest, put, call } from 'redux-saga/effects';

import { findMensagemFiscal } from '../service';
import {
  FIND_MENSAGEM_FISCAL,
  FIND_MENSAGEM_FISCAL_PENDING,
  FIND_MENSAGEM_FISCAL_SUCCESS,
  FIND_MENSAGEM_FISCAL_FAILURE
} from '../actionTypes';

function* sagaFindMensagemFiscal(action) {
  yield put({ type: FIND_MENSAGEM_FISCAL_PENDING, id: action.id })

  try {

    const registro = yield call(findMensagemFiscal, action.id);

    yield put({ type: FIND_MENSAGEM_FISCAL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_MENSAGEM_FISCAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindMensagemFiscal() {
  yield takeLatest(FIND_MENSAGEM_FISCAL, sagaFindMensagemFiscal)
}
