import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    status: true,
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres')
        .required('Este campo é obrigatório'),
});

export const mensagemFiscal = {
    newRegistro,
    validation
}