import { takeLatest, put, call, } from 'redux-saga/effects';
import swal from 'sweetalert';
import { history } from '@commons';
import { createProdutoInsumoCadastro, updateProdutoInsumoCadastro } from '../service';
import {
  CREATE_PRODUTO_INSUMO_CADASTRO,
  CREATE_PRODUTO_INSUMO_CADASTRO_PENDING,
  CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS,
  CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE,
} from '@handler';
import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateProdutoInsumoCadastro(action) {
  yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_PENDING });

  try {
    if (action.data.dadosFiscaisItemNfe.informarDadosNfe === false) {
      action.data = {
        ...action.data,
        unidadeNFe: null,
        dadosFiscaisItemNfe: {
          informarDadosNfe: false,
          nomeItemNF: '',
          ncm: '',
          ncmEx: '',
          cest: '',
          gtinEan: '',
          cbenef: '',
          unidadeTributavel: null,
          gtinEanTributavel: '',
          cfopEstadual: null,
          cfopInterestadual: null,
          cfopExportacao: null,
          tabelaTributaria: null,
        }
      }
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createProdutoInsumoCadastro, action.data);
    } else {
      yield call(updateProdutoInsumoCadastro, action.data);
    }

    yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS, registro: action.data });
    if (!action.isCadastroRapido) {
      swal({
        title: 'Insumo salvo com sucesso!',
        icon: 'success',
        buttons: {
          cancel: false,
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: 'btn-primary',
            closeModal: true
          }
        }
      }).then(() => history.push('/insumoCadastro'));
    } else {
      AlertSuccess('Insumo salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE, registro: action.data });
    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreateProdutoInsumoCadastro() {
  yield takeLatest(CREATE_PRODUTO_INSUMO_CADASTRO, sagaCreateProdutoInsumoCadastro);
}