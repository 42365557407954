import { takeLatest, put, call } from 'redux-saga/effects';

import { findFunrural } from '../service';
import {
  FIND_FUNRURAL,
  FIND_FUNRURAL_PENDING,
  FIND_FUNRURAL_SUCCESS,
  FIND_FUNRURAL_FAILURE
} from '../actionTypes';

function* sagaFindFunrural(action) {
  yield put({ type: FIND_FUNRURAL_PENDING, id: action.id })

  try {

    const registro = yield call(findFunrural, action.id);

    yield put({ type: FIND_FUNRURAL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_FUNRURAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindFunrural() {
  yield takeLatest(FIND_FUNRURAL, sagaFindFunrural)
}
