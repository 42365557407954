import { takeLatest, put, call } from 'redux-saga/effects';

import { findNFeEmissao } from '../service';
import {
  FIND_NFE_EMISSAO,
  FIND_NFE_EMISSAO_PENDING,
  FIND_NFE_EMISSAO_SUCCESS,
  FIND_NFE_EMISSAO_FAILURE
} from '../actionTypes';

function* sagaFindNFeEmissao(action) {
  yield put({ type: FIND_NFE_EMISSAO_PENDING, id: action.id })

  try {

    const defaultValues = {
      data: '',
      status: 'EM_DIGITACAO',
      tipoOperacao: 'S',
      naturezaOperacao: null,
      naturezaOperacaoConfig: {
        nfePermiteEdicaoImpostos: false,
        nfeNaturezaExigeCfopEspecifica: false,
        nfeCfopEstadual: null,
        nfeCfopInterestadual: null,
        nfeCfopExportacao: null,
        nfeExigeDocRef: false,
        nfeUsaTabelaTributariaNatureza: false,
        nfeTabelaTributaria: null,
        nfeTabelaTributariaMensagemFiscal: null,
        nfeTabelaTributariaIcms: {
          baseCalculo: null,
          reducao: null,
          modalidade: null,
          aliquota: null,
          situacaoTributaria: null,
          origemCst: null,
        },
        nfeTabelaTributariaIcmsSt: {
          reducao: null,
          modalidade: null,
          mva: null,
          aliquota: null,
        },
        nfeTabelaTributariaIpi: {
          situacaoTributaria: null,
          baseCalculo: null,
          aliquota: null,
        },
        nfeTabelaTributariaPis: {
          situacaoTributaria: null,
          baseCalculo: null,
          aliquota: null,
        },
        nfeTabelaTributariaPisSt: {
          baseCalculo: null,
          aliquota: null,
        },
        nfeTabelaTributariaCofins: {
          situacaoTributaria: null,
          baseCalculo: null,
          aliquota: null,
        },
        nfeTabelaTributariaCofinsSt: {
          baseCalculo: null,
          aliquota: null,
        },
      },
      finalidadeEmissao: 'NORMAL',
      numero: null,
      serie: null,
      identificador: null,
      emitente: {
        emitente: null,
        emitenteNome: null,
        emitenteApelido: null,
        emitenteTipoPessoa: null,
        emitenteCnpj: null,
        emitenteCpf: null,
        emitenteEmail: null,
        emitenteTelefone: null,
        emitenteCelular: null,
        emitenteIndicadorInscrEstadual: null,
        emitenteInscricaoEstadual: null,
        emitenteCep: null,
        emitenteLogradouro: null,
        emitenteBairro: null,
        emitenteComplemento: null,
        emitenteNumero: null,
        emitenteEstado: null,
        emitenteCidade: null,
      },
      destinatario: {
        destinatario: null,
        destinatarioNome: null,
        destinatarioApelido: null,
        destinatarioTipoPessoa: null,
        destinatarioCnpj: null,
        destinatarioCpf: null,
        destinatarioEmail: null,
        destinatarioTelefone: null,
        destinatarioCelular: null,
        destinatarioIndicadorInscrEstadual: null,
        destinatarioInscricaoEstadual: null,
        destinatarioCep: null,
        destinatarioLogradouro: null,
        destinatarioBairro: null,
        destinatarioComplemento: null,
        destinatarioNumero: null,
        destinatarioEstado: null,
        destinatarioCidade: null,
      },
      indicadorOperacaoConsumidor: 'NORMAL',
      consumidorPresenca: 'PRESENCIAL',
      mensagemInterna: '',
      mensagemNfeComplementar: '',
      mensagemNfeFisco: '',
      mensagemNfeAutomatico: '',
      transporte: {
        modalidadeFrete: null,
        transportadora: null,
        transportadoraNome: null,
        transportadoraApelido: null,
        transportadoraTipoPessoa: null,
        transportadoraCnpj: null,
        transportadoraCpf: null,
        transportadoraEmail: null,
        transportadoraTelefone: null,
        transportadoraCelular: null,
        transportadoraIndicadorInscrEstadual: null,
        transportadoraInscricaoEstadual: null,
        transportadoraCep: null,
        transportadoraLogradouro: null,
        transportadoraBairro: null,
        transportadoraComplemento: null,
        transportadoraNumero: null,
        transportadoraEstado: null,
        transportadoraCidade: null,
        veiculo: null,
        veiculoPlaca: null,
        veiculoEmplacadoNoBrasil: null,
        veiculoEstadoEmplacamento: null,
        veiculoRNTC: null,
        dataEntradaSaida: null,
        horaEntradaSaida: null,
        enderecoEntregaInformado: false,
        enderecoEntregaTipoPessoa: null,
        enderecoEntregaCnpj: null,
        enderecoEntregaCpf: null,
        enderecoEntregaNome: null,
        enderecoEntregaCep: null,
        enderecoEntregaLogradouro: null,
        enderecoEntregaBairro: null,
        enderecoEntregaComplemento: null,
        enderecoEntregaNumero: null,
        enderecoEntregaEstado: null,
        enderecoEntregaCidade: null,
        enderecoRetiradaInformado: false,
        enderecoRetiradaTipoPessoa: null,
        enderecoRetiradaCnpj: null,
        enderecoRetiradaCpf: null,
        enderecoRetiradaNome: null,
        enderecoRetiradaCep: null,
        enderecoRetiradaLogradouro: null,
        enderecoRetiradaBairro: null,
        enderecoRetiradaComplemento: null,
        enderecoRetiradaNumero: null,
        enderecoRetiradaEstado: null,
        enderecoRetiradaCidade: null,
      },
      transporte: {
        modalidadeFrete: null,
        transportadora: null,
        transportadoraNome: null,
        transportadoraApelido: null,
        transportadoraTipoPessoa: null,
        transportadoraCnpj: null,
        transportadoraCpf: null,
        transportadoraEmail: null,
        transportadoraTelefone: null,
        transportadoraCelular: null,
        transportadoraIndicadorInscrEstadual: null,
        transportadoraInscricaoEstadual: null,
        transportadoraCep: null,
        transportadoraLogradouro: null,
        transportadoraBairro: null,
        transportadoraComplemento: null,
        transportadoraNumero: null,
        transportadoraEstado: null,
        transportadoraCidade: null,
        veiculo: null,
        veiculoPlaca: null,
        veiculoEmplacadoNoBrasil: null,
        veiculoEstadoEmplacamento: null,
        veiculoRNTC: null,
        dataHoraEntradaSaida: null,
        enderecoEntregaInformado: false,
        enderecoEntregaTipoPessoa: null,
        enderecoEntregaCnpj: null,
        enderecoEntregaCpf: null,
        enderecoEntregaNome: null,
        enderecoEntregaCep: null,
        enderecoEntregaLogradouro: null,
        enderecoEntregaBairro: null,
        enderecoEntregaComplemento: null,
        enderecoEntregaNumero: null,
        enderecoEntregaEstado: null,
        enderecoEntregaCidade: null,
        enderecoRetiradaInformado: false,
        enderecoRetiradaTipoPessoa: null,
        enderecoRetiradaCnpj: null,
        enderecoRetiradaCpf: null,
        enderecoRetiradaNome: null,
        enderecoRetiradaCep: null,
        enderecoRetiradaLogradouro: null,
        enderecoRetiradaBairro: null,
        enderecoRetiradaComplemento: null,
        enderecoRetiradaNumero: null,
        enderecoRetiradaEstado: null,
        enderecoRetiradaCidade: null,
      },
      transporteVolumes: [],
      nfeDup: {
          formaPgto: null,
          metodoPgto: null,
      },
      itens: [],
      parcelas: [],
      eventos: [],
      retornoArquivos: [],
      acessosXml: [],
      docsRef: [],
      total: {
        totalIcmsBc: 0,
        totalIcmsValor: 0,
        totalIcmsStBc: 0,
        totalIcmsStValor: 0,
        totalIpiBc: 0,
        totalIpiValor: 0,
        totalPisBc: 0,
        totalPisValor: 0,
        totalPisStBc: 0,
        totalPisStValor: 0,
        totalCofinsBc: 0,
        totalCofinsValor: 0,
        totalCofinsStBc: 0,
        totalCofinsStValor: 0,
        totalProdutos: 0,
        totalFrete: 0,
        totalSeguro: 0,
        totalOutrasDespesas: 0,
        totalDesconto: 0,
        totalNFe: 0,
      },
    };

    const registro = yield call(findNFeEmissao, action.id);

    // Mescla os valores de 'registro' com os valores padrão
    const finalRegistro = {
      ...defaultValues,
      ...registro,
      naturezaOperacaoConfig: {
        ...defaultValues.naturezaOperacaoConfig,
        ...registro?.naturezaOperacaoConfig,
      },
      emitente: {
        ...defaultValues.emitente,
        ...registro?.emitente,
      },
      destinatario: {
        ...defaultValues.destinatario,
        ...registro?.destinatario,
      },
      transporte: {
        ...defaultValues.transporte,
        ...registro?.transporte,
      },
      nfeDup: {
        ...defaultValues.nfeDup,
        ...registro?.nfeDup,
      },
      total: {
        ...defaultValues.total,
        ...registro?.total,
      },
      transporteVolumes: registro?.transporteVolumes || defaultValues.transporteVolumes,
      parcelas: registro?.parcelas || defaultValues.parcelas,
      eventos: registro?.eventos || defaultValues.eventos,
      retornoArquivos: registro?.retornoArquivos || defaultValues.retornoArquivos,
      acessosXml: registro?.acessosXml || defaultValues.acessosXml,
      docsRef: registro?.docsRef || defaultValues.docsRef,
    };

    // Emite o sucesso com o registro final preenchido
    yield put({ type: FIND_NFE_EMISSAO_SUCCESS, registro: finalRegistro });

  } catch (error) {

    yield put({ type: FIND_NFE_EMISSAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindNFeEmissao() {
  yield takeLatest(FIND_NFE_EMISSAO, sagaFindNFeEmissao)
}
