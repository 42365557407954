
export const NEW_MENSAGEM_FISCAL = 'NEW_MENSAGEM_FISCAL'
export const NEW_MENSAGEM_FISCAL_SUCCESS = 'NEW_MENSAGEM_FISCAL_SUCCESS'

export const CREATE_MENSAGEM_FISCAL = 'CREATE_MENSAGEM_FISCAL'
export const CREATE_MENSAGEM_FISCAL_PENDING = 'CREATE_MENSAGEM_FISCAL_PENDING'
export const CREATE_MENSAGEM_FISCAL_SUCCESS = 'CREATE_MENSAGEM_FISCAL_SUCCESS'
export const CREATE_MENSAGEM_FISCAL_FAILURE = 'CREATE_MENSAGEM_FISCAL_FAILURE'

export const CANCEL_MENSAGEM_FISCAL_RAPIDO = 'CANCEL_MENSAGEM_FISCAL_RAPIDO'
export const CANCEL_MENSAGEM_FISCAL_RAPIDO_SUCCESS = 'CANCEL_MENSAGEM_FISCAL_RAPIDO_SUCCESS'

export const TABLE_MENSAGEM_FISCAL = 'TABLE_MENSAGEM_FISCAL'
export const TABLE_MENSAGEM_FISCAL_PENDING = 'TABLE_MENSAGEM_FISCAL_PENDING'
export const TABLE_MENSAGEM_FISCAL_SUCCESS = 'TABLE_MENSAGEM_FISCAL_SUCCESS'
export const TABLE_MENSAGEM_FISCAL_FAILURE = 'TABLE_MENSAGEM_FISCAL_FAILURE'

export const LIST_MENSAGEM_FISCAL = 'LIST_MENSAGEM_FISCAL'
export const LIST_MENSAGEM_FISCAL_PENDING = 'LIST_MENSAGEM_FISCAL_PENDING'
export const LIST_MENSAGEM_FISCAL_SUCCESS = 'LIST_MENSAGEM_FISCAL_SUCCESS'
export const LIST_MENSAGEM_FISCAL_FAILURE = 'LIST_MENSAGEM_FISCAL_FAILURE'

export const FIND_MENSAGEM_FISCAL = 'FIND_MENSAGEM_FISCAL'
export const FIND_MENSAGEM_FISCAL_PENDING = 'FIND_MENSAGEM_FISCAL_PENDING'
export const FIND_MENSAGEM_FISCAL_SUCCESS = 'FIND_MENSAGEM_FISCAL_SUCCESS'
export const FIND_MENSAGEM_FISCAL_FAILURE = 'FIND_MENSAGEM_FISCAL_FAILURE'

export const DELETE_MENSAGEM_FISCAL = 'DELETE_MENSAGEM_FISCAL'
export const DELETE_MENSAGEM_FISCAL_PENDING = 'DELETE_MENSAGEM_FISCAL_PENDING'
export const DELETE_MENSAGEM_FISCAL_SUCCESS = 'DELETE_MENSAGEM_FISCAL_SUCCESS'
export const DELETE_MENSAGEM_FISCAL_FAILURE = 'DELETE_MENSAGEM_FISCAL_FAILURE'
