const newRegistro = {
    automatico: false,
    data: '',
    tipoPagamento: null,
    valorCapital: 0,
    valorDesconto: 0,
    valorJuro: 0,
    valorMulta: 0,
    valorOutrasDespesas: 0,
    valorTotal: 0,
    conta: null,
    cartaoBandeira: '',
    cartaoNsu: '',
    chequeData: '',
    chequeEmitente: '',
    chequeNro: '',
    chequeBanco: '',
    chequeNroAg: '',
    chequeDvAg: '',
    chequeNroCc: '',
    chequeDvCc: '',
    obs: '',
};

export const baixaConta = {
    newRegistro
}