import * as Yup from 'yup';

const newRegistro = {
    data: '',
    status: 'EM_DIGITACAO',
    tipoOperacao: 'S',
    naturezaOperacao: null,
    naturezaOperacaoConfig: {
        nfePermiteEdicaoImpostos: false,
        nfeNaturezaExigeCfopEspecifica: false,
        nfeCfopEstadual: null,
        nfeCfopInterestadual: null,
        nfeCfopExportacao: null,
        nfeExigeDocRef: false,
        nfeCalculaIcms: false,
        nfeCalculaIcmsSt: false,
        nfeCalculaIpi: false,
        nfeCalculaPis: false,
        nfeCalculaPisSt: false,
        nfeCalculaCofins: false,
        nfeCalculaCofinsSt: false,
        nfeCalculaFunrural: false,
        nfeDestacarIcmsDanfe: false,
        nfeIncluiIpiCalculoICMS: false,
        nfeNaoInformarCbenef: false,
        nfeUtilizarCbenefDaOperacao: false,
        nfeCbenef: null,
        nfeUsaTabelaTributariaNatureza: false,
        nfeMensagemFiscal: null,
        nfeTabelaTributaria: null,
        nfeTabelaTributariaMensagemFiscal: null,
        nfeTabelaTributariaIcms: {
            baseCalculo: null,
            reducao: null,
            modalidade: null,
            aliquota: null,
            situacaoTributaria: null,
            origemCst: null
        },
        nfeTabelaTributariaIcmsSt: {
            reducao: null,
            modalidade: null,
            mva: null,
            aliquota: null
        },
        nfeTabelaTributariaIpi: {
            situacaoTributaria: null,
            baseCalculo: null,
            aliquota: null
        },
        nfeTabelaTributariaPis: {
            situacaoTributaria: null,
            baseCalculo: null,
            aliquota: null
        },
        nfeTabelaTributariaPisSt: {
            baseCalculo: null,
            aliquota: null
        },
        nfeTabelaTributariaCofins: {
            situacaoTributaria: null,
            baseCalculo: null,
            aliquota: null
        },
        nfeTabelaTributariaCofinsSt: {
            baseCalculo: null,
            aliquota: null
        },
    },
    finalidadeEmissao: 'NORMAL',
    numero: null,
    serie: null,
    identificador: null,
    emitente: {
        emitente: null,
        emitenteNome: null,
        emitenteApelido: null,
        emitenteTipoPessoa: null,
        emitenteCnpj: null,
        emitenteCpf: null,
        emitenteEmail: null,
        emitenteTelefone: null,
        emitenteCelular: null,
        emitenteIndicadorInscrEstadual: null,
        emitenteInscricaoEstadual: null,
        emitenteCep: null,
        emitenteLogradouro: null,
        emitenteBairro: null,
        emitenteComplemento: null,
        emitenteNumero: null,
        emitenteEstado: null,
        emitenteCidade: null,
    },
    destinatario: {
        destinatario: null,
        destinatarioNome: null,
        destinatarioApelido: null,
        destinatarioTipoPessoa: null,
        destinatarioCnpj: null,
        destinatarioCpf: null,
        destinatarioEmail: null,
        destinatarioTelefone: null,
        destinatarioCelular: null,
        destinatarioIndicadorInscrEstadual: null,
        destinatarioInscricaoEstadual: null,
        destinatarioCep: null,
        destinatarioLogradouro: null,
        destinatarioBairro: null,
        destinatarioComplemento: null,
        destinatarioNumero: null,
        destinatarioEstado: null,
        destinatarioCidade: null,
    },
    indicadorOperacaoConsumidor: 'NORMAL',
    consumidorPresenca: 'PRESENCIAL',
    naoInformarMensagemAutomatica: false,
    mensagemInterna: '',
    mensagemNfeComplementar: '',
    mensagemNfeFisco: '',
    mensagemNfeAutomatico: '',
    transporte: {
        modalidadeFrete: null,
        transportadora: null,
        transportadoraNome: null,
        transportadoraApelido: null,
        transportadoraTipoPessoa: null,
        transportadoraCnpj: null,
        transportadoraCpf: null,
        transportadoraEmail: null,
        transportadoraTelefone: null,
        transportadoraCelular: null,
        transportadoraIndicadorInscrEstadual: null,
        transportadoraInscricaoEstadual: null,
        transportadoraCep: null,
        transportadoraLogradouro: null,
        transportadoraBairro: null,
        transportadoraComplemento: null,
        transportadoraNumero: null,
        transportadoraEstado: null,
        transportadoraCidade: null,
        veiculo: null,
        veiculoPlaca: null,
        veiculoEmplacadoNoBrasil: null,
        veiculoEstadoEmplacamento: null,
        veiculoRNTC: null,
        dataHoraEntradaSaida: null,
        enderecoEntregaInformado: false,
        enderecoEntregaTipoPessoa: null,
        enderecoEntregaCnpj: null,
        enderecoEntregaCpf: null,
        enderecoEntregaNome: null,
        enderecoEntregaCep: null,
        enderecoEntregaLogradouro: null,
        enderecoEntregaBairro: null,
        enderecoEntregaComplemento: null,
        enderecoEntregaNumero: null,
        enderecoEntregaEstado: null,
        enderecoEntregaCidade: null,
        enderecoRetiradaInformado: false,
        enderecoRetiradaTipoPessoa: null,
        enderecoRetiradaCnpj: null,
        enderecoRetiradaCpf: null,
        enderecoRetiradaNome: null,
        enderecoRetiradaCep: null,
        enderecoRetiradaLogradouro: null,
        enderecoRetiradaBairro: null,
        enderecoRetiradaComplemento: null,
        enderecoRetiradaNumero: null,
        enderecoRetiradaEstado: null,
        enderecoRetiradaCidade: null,
    },
    transporteVolumes: [],
    itens: [],
    nfeDup: {
        formaPgto: null,
        metodoPgto: null,
    },
    parcelas: [],
    eventos: [],
    retornoArquivos: [],
    acessosXml: [],
    docsRef: [],
    total: {
        totalIcmsBc: 0,
        totalIcmsValor: 0,
        totalIcmsStBc: 0,
        totalIcmsStValor: 0,
        totalIpiBc: 0,
        totalIpiValor: 0,
        totalPisBc: 0,
        totalPisValor: 0,
        totalPisStBc: 0,
        totalPisStValor: 0,
        totalCofinsBc: 0,
        totalCofinsValor: 0,
        totalCofinsStBc: 0,
        totalCofinsStValor: 0,
        totalProdutos: 0,
        totalFrete: 0,
        totalSeguro: 0,
        totalOutrasDespesas: 0,
        totalDesconto: 0,
        totalNFe: 0
    },
    funrural: {
        aliqInss: 0,
        aliqRat: 0,
        aliqSenar: 0,
        aliqFunrural: 0,
        totalInss: 0,
        totalRat: 0,
        totalSenar: 0,
        totalFunrural: 0,
    }
};

const validation = Yup.object().shape({
    tipoOperacao: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    finalidadeEmissao: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    indicadorOperacaoConsumidor: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    consumidorPresenca: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    mensagemInterna: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    mensagemNfeComplementar: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    mensagemNfeFisco: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    mensagemNfeAutomatico: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    naturezaOperacao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    emitente: Yup.object().shape({
        emitente: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
    }),
    destinatario: Yup.object().shape({
        destinatario: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
    }),
    transporte: Yup.object().shape({
        modalidadeFrete: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório'),
    }),
    transporteVolumes: Yup.array()
        .of(
            Yup.object().shape({
                especieTransporte: Yup.string()
                    .max(60, 'Campo deve ter menos do que 60 caracteres!'),
                marcaTransporte: Yup.string()
                    .max(60, 'Campo deve ter menos do que 60 caracteres!'),
                numeracaoTransporte: Yup.string()
                    .max(60, 'Campo deve ter menos do que 60 caracteres!'),
            })
        ),
    acessosXml: Yup.array()
        .of(
            Yup.object().shape({
                tipoPessoa: Yup.string()
                    .typeError('Este campo é obrigatório')
                    .required('Este campo é obrigatório'),

            })
        ),
    nfeDup: Yup.object().shape({
        formaPgto: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório'),
        metodoPgto: Yup.string()
            .nullable()
            .when('formaPgto', {
                is: (formaPgto) => formaPgto === 'A_VISTA' || formaPgto === 'A_PRAZO',
                then: Yup.string().required('Este campo é obrigatório').nullable(),
                otherwise: Yup.string().nullable(),
            }),
    }),
    docsRef: Yup.array()
        .of(
            Yup.object().shape({
                tipo: Yup.string()
                    .typeError('Este campo é obrigatório')
                    .required('Este campo é obrigatório'),
                tipoNfeChaveAcesso: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NFE',
                        then: Yup.string()
                            .matches(/^\d+$/, 'A chave de acesso deve conter apenas números')
                            .min(44, 'Chave de acesso possui 44 números')
                            .max(44, 'Chave de acesso possui 44 números')
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoCteChaveAcesso: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'CTE',
                        then: Yup.string()
                            .matches(/^\d+$/, 'A chave de acesso deve conter apenas números')
                            .min(44, 'Chave de acesso possui 44 números')
                            .max(44, 'Chave de acesso possui 44 números')
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfSerie: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_IMPRESSA',
                        then: Yup.string()
                            .matches(/^\d+$/, 'O campo deve conter apenas números')
                            .max(3, 'Campo deve ter menos do que 3 caracteres!')
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfNumero: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_IMPRESSA',
                        then: Yup.string()
                            .matches(/^\d+$/, 'O campo deve conter apenas números')
                            .max(9, 'Campo deve ter menos do que 9 caracteres!')
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfModelo: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_IMPRESSA',
                        then: Yup.string()
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfData: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_IMPRESSA',
                        then: Yup.string()
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfCnpj: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_IMPRESSA',
                        then: Yup.string()
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfEstado: Yup.object()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_IMPRESSA',
                        then: Yup.object()
                            .shape({
                                id: Yup.number()
                                    .typeError('Este campo é obrigatório')
                                    .required('Este campo é obrigatório'),
                            })
                            .typeError('Este campo é obrigatório')
                            .required('Este campo é obrigatório'),
                        otherwise: Yup.object().nullable(),
                    }),
                tipoNfProdutorSerie: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_PRODUTOR',
                        then: Yup.string()
                            .matches(/^\d+$/, 'O campo deve conter apenas números')
                            .max(3, 'Campo deve ter menos do que 3 caracteres!')
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfProdutorNumero: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_PRODUTOR',
                        then: Yup.string()
                            .matches(/^\d+$/, 'O campo deve conter apenas números')
                            .max(9, 'Campo deve ter menos do que 9 caracteres!')
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfProdutorModelo: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_PRODUTOR',
                        then: Yup.string()
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfProdutorData: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_PRODUTOR',
                        then: Yup.string()
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfProdutorTipoPessoa: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_PRODUTOR',
                        then: Yup.string()
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfProdutorCpf: Yup.string()
                    .nullable()
                    .when(['tipo', 'tipoNfProdutorTipoPessoa'], {
                        is: (tipo, tipoPessoa) => tipo === 'NF_PRODUTOR' && tipoPessoa === 'FISICA',
                        then: Yup.string()
                            .required('Este campo é obrigatório')
                            .nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfProdutorCnpj: Yup.string()
                    .nullable()
                    .when(['tipo', 'tipoNfProdutorTipoPessoa'], {
                        is: (tipo, tipoPessoa) => tipo === 'NF_PRODUTOR' && tipoPessoa === 'JURIDICA',
                        then: Yup.string()
                            .required('Este campo é obrigatório')
                            .nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfProdutorInscEstadual: Yup.string()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_PRODUTOR',
                        then: Yup.string()
                            .min(2, 'Campo deve ter pelo menos 2 caracteres!')
                            .max(14, 'Campo deve ter menos do que 14 caracteres!')
                            .required('Este campo é obrigatório').nullable(),
                        otherwise: Yup.string().nullable(),
                    }),
                tipoNfProdutorEstado: Yup.object()
                    .nullable()
                    .when('tipo', {
                        is: (tipo) => tipo === 'NF_PRODUTOR',
                        then: Yup.object()
                            .shape({
                                id: Yup.number()
                                    .typeError('Este campo é obrigatório')
                                    .required('Este campo é obrigatório'),
                            })
                            .typeError('Este campo é obrigatório')
                            .required('Este campo é obrigatório'),
                        otherwise: Yup.object().nullable(),
                    }),
            }),

        ),
});

export const nfeEmissao = {
    newRegistro,
    validation
}