import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createCertificadoDigitalCadastro, updateCertificadoDigitalCadastro } from '../service';
import {
  CREATE_CERTIFICADO_DIGITAL_CADASTRO,
  CREATE_CERTIFICADO_DIGITAL_CADASTRO_PENDING,
  CREATE_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS,
  CREATE_CERTIFICADO_DIGITAL_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaCreateCertificadoDigitalCadastro(action) {
  yield put({ type: CREATE_CERTIFICADO_DIGITAL_CADASTRO_PENDING })

  try {
    if (action.data.senhaCertificadoTemp == '') {
      delete action.data.senhaCertificadoTemp;
    }
    if (action.data.tipoPessoa === 'FISICA'){
      action.data.cnpj = null;
    } else {
      action.data.cpf = null;
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createCertificadoDigitalCadastro, {
        ...action.data
      });
    } else {
      yield call(updateCertificadoDigitalCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/fiscal/certificadoDigital');
    }

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_CERTIFICADO_DIGITAL_CADASTRO_FAILURE, registro: action.data })

    if (error.response.status !== undefined && error.response.status == 403) {
      AlertWarning('Sua licença atingiu o número máximo de certificados cadastrados. Para contratar mais certificados, contate o comercial da plataforma.');
    } else {
      if (error.response.status !== undefined && error.response.status == 400) {
        AlertWarning('Não foi possível salvar o certificado digital. Verifique se o CPF ou CNPJ já está cadastrado no sistema.');
      } else {
        AlertError('Falha ao salvar o registro! Tente novamente...');
      }
    }
  }

}

export default function* watchCreateCertificadoDigitalCadastro() {
  yield takeLatest(CREATE_CERTIFICADO_DIGITAL_CADASTRO, sagaCreateCertificadoDigitalCadastro)
}
