import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_MENSAGEM_FISCAL_RAPIDO,
    CANCEL_MENSAGEM_FISCAL_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelMensagemFiscalRapido() {
  yield put({ type: CANCEL_MENSAGEM_FISCAL_RAPIDO_SUCCESS})
}

export default function* watchCancelMensagemFiscalRapido() {
  yield takeLatest(CANCEL_MENSAGEM_FISCAL_RAPIDO, sagaCancelMensagemFiscalRapido)
}