import { takeLatest, put, call } from 'redux-saga/effects';

import {
  CORRIGIR_CCE_NFE_EMISSAO,
  CORRIGIR_CCE_NFE_EMISSAO_PENDING,
  CORRIGIR_CCE_NFE_EMISSAO_SUCCESS,
  CORRIGIR_CCE_NFE_EMISSAO_FAILURE
} from '../actionTypes';
import { AlertError, AlertSuccess, AlertWarning } from '@components/common/AlertToast';
import { corrigirCCeNFeEmissao, findNFeEmissao } from '../service';

function* sagaCorrigirCCeNFeEmissao(action) {
  yield put({ type: CORRIGIR_CCE_NFE_EMISSAO_PENDING })

  try {
    yield call(corrigirCCeNFeEmissao, action.idNota, action.correcao);

    const registro = yield call(findNFeEmissao, action.idNota);

    yield put({ type: CORRIGIR_CCE_NFE_EMISSAO_SUCCESS, registro: registro })

    AlertSuccess('NFe corrigida com sucesso!');
  } catch (error) {
    if (error.response.status === 400) {
      AlertWarning(error.response.data);
    } else {
      AlertError('Falha ao corrigir a NFe! Verifique as regras para correção da NFe.');
    }
    yield put({ type: CORRIGIR_CCE_NFE_EMISSAO_FAILURE })
  }
}

export default function* watchCorrigirCCeNFeEmissao() {
  yield takeLatest(CORRIGIR_CCE_NFE_EMISSAO, sagaCorrigirCCeNFeEmissao)
}
