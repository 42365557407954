import { path, httpService } from '@commons';
import { httpServiceApiDfe } from '@commons/http.service.api.dfe'; 
import { pathApiDfe } from '@commons/path.api.dfe';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fiscal/certificado-digital' };
const argsApiDfe = { ...pathApiDfe };
/////////////////////////////////////////////////////////////////////////////////

export function createCertificadoDigitalCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateCertificadoDigitalCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listCertificadoDigitalCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findCertificadoDigitalCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllCertificadoDigitalCadastro() {
  return httpService.get({ ...args, path: '/fiscal/certificado-digital/all' });
}

export function deleteCertificadoDigitalCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function validarCertificado(certificado, senha) {
  var bodyFormData = new FormData();
  bodyFormData.append('certificado', certificado);
  
  // Codifica a senha para garantir que caracteres especiais sejam corretamente interpretados
  const senhaCodificada = encodeURIComponent(senha);
  
  return httpServiceApiDfe.postFile({ ...argsApiDfe, pathApiDfe: 'empresa/validarCertificado/' + senhaCodificada }, bodyFormData);
}


export function getNroCpfCnpjMonitoradosNFeCompra() {
  return httpService.get({ ...args, args: `/nroCpfCnpjMonitoradosNFeCompra` });
}