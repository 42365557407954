import { takeLatest, put } from 'redux-saga/effects';

import {
  mensagemFiscal,
  NEW_MENSAGEM_FISCAL,
  NEW_MENSAGEM_FISCAL_SUCCESS,
} from '@handler';

function* sagaNewMensagemFiscal(action) {

  yield put({ 
    type: NEW_MENSAGEM_FISCAL_SUCCESS, 
    registro: mensagemFiscal.newRegistro,
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewMensagemFiscal() {
  yield takeLatest(NEW_MENSAGEM_FISCAL, sagaNewMensagemFiscal)
}
