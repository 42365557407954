
export const NEW_CERTIFICADO_DIGITAL_CADASTRO = 'NEW_CERTIFICADO_DIGITAL_CADASTRO'
export const NEW_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS = 'NEW_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS'

export const CREATE_CERTIFICADO_DIGITAL_CADASTRO = 'CREATE_CERTIFICADO_DIGITAL_CADASTRO'
export const CREATE_CERTIFICADO_DIGITAL_CADASTRO_PENDING = 'CREATE_CERTIFICADO_DIGITAL_CADASTRO_PENDING'
export const CREATE_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS = 'CREATE_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS'
export const CREATE_CERTIFICADO_DIGITAL_CADASTRO_FAILURE = 'CREATE_CERTIFICADO_DIGITAL_CADASTRO_FAILURE'

export const CANCEL_CERTIFICADO_DIGITAL_CADASTRO_RAPIDO = 'CANCEL_CERTIFICADO_DIGITAL_CADASTRO_RAPIDO'
export const CANCEL_CERTIFICADO_DIGITAL_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_CERTIFICADO_DIGITAL_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_CERTIFICADO_DIGITAL_CADASTRO = 'TABLE_CERTIFICADO_DIGITAL_CADASTRO'
export const TABLE_CERTIFICADO_DIGITAL_CADASTRO_PENDING = 'TABLE_CERTIFICADO_DIGITAL_CADASTRO_PENDING'
export const TABLE_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS = 'TABLE_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS'
export const TABLE_CERTIFICADO_DIGITAL_CADASTRO_FAILURE = 'TABLE_CERTIFICADO_DIGITAL_CADASTRO_FAILURE'

export const LIST_CERTIFICADO_DIGITAL_CADASTRO = 'LIST_CERTIFICADO_DIGITAL_CADASTRO'
export const LIST_CERTIFICADO_DIGITAL_CADASTRO_PENDING = 'LIST_CERTIFICADO_DIGITAL_CADASTRO_PENDING'
export const LIST_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS = 'LIST_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS'
export const LIST_CERTIFICADO_DIGITAL_CADASTRO_FAILURE = 'LIST_CERTIFICADO_DIGITAL_CADASTRO_FAILURE'

export const FIND_CERTIFICADO_DIGITAL_CADASTRO = 'FIND_CERTIFICADO_DIGITAL_CADASTRO'
export const FIND_CERTIFICADO_DIGITAL_CADASTRO_PENDING = 'FIND_CERTIFICADO_DIGITAL_CADASTRO_PENDING'
export const FIND_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS = 'FIND_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS'
export const FIND_CERTIFICADO_DIGITAL_CADASTRO_FAILURE = 'FIND_CERTIFICADO_DIGITAL_CADASTRO_FAILURE'

export const DELETE_CERTIFICADO_DIGITAL_CADASTRO = 'DELETE_CERTIFICADO_DIGITAL_CADASTRO'
export const DELETE_CERTIFICADO_DIGITAL_CADASTRO_PENDING = 'DELETE_CERTIFICADO_DIGITAL_CADASTRO_PENDING'
export const DELETE_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS = 'DELETE_CERTIFICADO_DIGITAL_CADASTRO_SUCCESS'
export const DELETE_CERTIFICADO_DIGITAL_CADASTRO_FAILURE = 'DELETE_CERTIFICADO_DIGITAL_CADASTRO_FAILURE'
