import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classname from "classnames";
import { trackEvent } from "mixpanel";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    // if (matchingMenuItem) {
    //   this.activateParentDropdown(matchingMenuItem);
    // }
  }

  // activateParentDropdown = item => {
  //   item.classList.add("active");
  //   const parent = item.parentElement;

  //   if (parent) {
  //     parent.classList.add("active"); // li
  //     const parent2 = parent.parentElement;
  //     parent2.classList.add("active"); // li
  //     const parent3 = parent2.parentElement;
  //     if (parent3) {
  //       parent3.classList.add("active"); // li
  //       const parent4 = parent3.parentElement;
  //       if (parent4) {
  //         parent4.classList.add("active"); // li
  //         const parent5 = parent4.parentElement;
  //         if (parent5) {
  //           parent5.classList.add("active"); // li
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // };

  closeAllMenu() {
    this.setState({
      MenuCadastroState: false,
      MenuAgriculturaState: false,
      MenuPatrimonioState: false,
      MenuFinanceiroState: false,
      MenuEstoqueState: false,
      MenuFiscalState: false,
      MenuPecuariaState: false,
      MenuVisoesState: false,
      EntidadesState: false,
      FuncionarioState: false,
      CadastroAuxState: false,
      EstoqueInsumosState: false,
      EstoqueRelatoriosInsumosState: false,
      EstoqueProducaoState: false,
      EstoqueRelatoriosProducaoState: false,
      CadastrosFinanceiroState: false,
      ConfiguracaoFiscalState: false
    });
    this.props.funcCloseMenu();
  }

  render() {
    const { startSessionReducer } = this.props;

    const moduloAgricultura = startSessionReducer.configSession !== null && startSessionReducer.configSession.moduloAtivo !== null && startSessionReducer.configSession.moduloAtivo === 'AGRICULTURA';
    const moduloPecuaria = startSessionReducer.configSession !== null && startSessionReducer.configSession.moduloAtivo !== null && startSessionReducer.configSession.moduloAtivo === 'PECUARIA';

    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
              >
                <div id="navigation">
                  <ul className="navbar-nav">


                    <li className="nav-item">
                      <Link className="nav-link" to="/primeirosPassos"
                        onClick={e => {
                          trackEvent('Clicou no Menu Comece Aqui');
                          this.closeAllMenu();
                        }}>
                        <i className="fa fa-handshake mr-2"></i>Comece Aqui
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuVisoesState: !this.state.MenuVisoesState });
                        }}
                      >
                        <i className="mdi mdi-view-dashboard mr-2"></i>Visões Agrare
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuVisoesState
                        })}
                      >

                        <Link to="/dashboard" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Dashboard');
                            this.closeAllMenu();
                          }}>
                          Dashboard
                        </Link>
                        <Link to="/mapa" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Mapa');
                            this.closeAllMenu();
                          }}>
                          Mapa
                        </Link>
                        <Link to="/calendario" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Calendário');
                            this.closeAllMenu();
                          }}>
                          Calendário
                        </Link>
                      </div>
                    </li>


                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuCadastroState: !this.state.MenuCadastroState });
                        }}
                      >
                        <i className="mdi mdi-tag-plus mr-2"></i>Cadastros
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuCadastroState
                        })}
                      >
                        {!moduloAgricultura ? <React.Fragment /> :
                          <React.Fragment>
                            <Link to="/culturaCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Cultura');
                                this.closeAllMenu();
                              }}>
                              Cultura
                            </Link>

                            <Link to="/produtoAgricolaCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros >  Produtos Agrícola');
                                this.closeAllMenu();
                              }}>
                              Produtos Agrícola
                            </Link>

                            <Link to="/talhaoCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Talhão');
                                this.closeAllMenu();
                              }}>
                              Talhão
                            </Link>

                            <Link to="/safraCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Safra');
                                this.closeAllMenu();
                              }}>
                              Safra
                            </Link>
                          </React.Fragment>
                        }

                        {!moduloPecuaria ? <React.Fragment /> :
                          <React.Fragment>

                            <Link to="/pecuaria/campoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Campo
                            </Link>

                            <Link to="/pecuaria/anoPecuarioCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Ano Pecuário
                            </Link>

                            <Link to="/pecuaria/procedimentoPecuarioCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Procedimento Pecuário
                            </Link>

                            <div className="dropdown">
                              <Link
                                to="/#"
                                className="dropdown-item dropdown-toggle arrow-none"
                                onClick={e => {
                                  e.preventDefault();
                                  this.setState({
                                    EmailState: !this.state.EmailState
                                  });
                                }}
                              >
                                Animal <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: this.state.EmailState
                                })}
                                aria-labelledby="topnav-email"
                              >
                                <Link to="/pecuaria/animalCategoriaCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Categoria
                                </Link>

                                <Link to="/pecuaria/animalEspecieCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Espécie
                                </Link>

                                <Link to="/pecuaria/animalLoteCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Lote
                                </Link>

                                <Link to="/pecuaria/animalPelagemCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Pelagem
                                </Link>

                                <Link to="/pecuaria/animalRacaCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Raça
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>}

                        <Link to="/insumoCadastro" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Cadastros >  Insumos Agrícola');
                            this.closeAllMenu();
                          }}>
                          Insumos Agrícola
                        </Link>

                        {!moduloAgricultura ? <React.Fragment /> :
                          <React.Fragment>
                            <Link to="/procedimentoCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Procedimento');
                                this.closeAllMenu();
                              }}>
                              Procedimentos Agrícola
                            </Link>
                          </React.Fragment>
                        }

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EntidadesState: !this.state.EntidadesState
                              });
                            }}
                          >
                            Entidades <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EntidadesState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/clienteCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Pessoas > Cliente');
                                this.closeAllMenu();
                              }}>
                              Cliente
                            </Link>
                            <Link to="/fornecedorCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Pessoas > Fornecedor');
                                this.closeAllMenu();
                              }}>
                              Fornecedor
                            </Link>
                            <Link to="/transportadoraCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Pessoas > Transportadora');
                                this.closeAllMenu();
                              }}>
                              Transportadora
                            </Link>
                            <Link to="/funcionarioCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Pessoas > Funcionário > Cadastro');
                                this.closeAllMenu();
                              }}>
                              Funcionário
                            </Link>
                            <Link to="/motoristaCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Pessoas > Motorista');
                                this.closeAllMenu();
                              }}>
                              Motorista
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                CadastroAuxState: !this.state.CadastroAuxState
                              });
                            }}
                          >
                            Cadastros Auxiliares <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.CadastroAuxState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/funcionarioCargoCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Pessoas > Funcionário > Cargo');
                                this.closeAllMenu();
                              }}>
                              Cargo Funcionário
                            </Link>
                            <Link to="/unidadeMedidaConversaoCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Cadastros Auxiliares > Conversão de Unidade de Medida');
                                this.closeAllMenu();
                              }}>
                              Conversões de Unidades
                            </Link>

                            {!moduloAgricultura ? <React.Fragment /> :
                              <Link to="/despesaDiretaCadastro" className="dropdown-item"
                                onClick={e => {
                                  trackEvent('Clicou no Menu Cadastros > Cadastros Auxiliares > Despesa Direta');
                                  this.closeAllMenu();
                                }}>
                                Despesa Direta
                              </Link>
                            }

                            {!moduloAgricultura ? <React.Fragment /> :
                              <Link to="/agricultura/aplicacao/estagio" className="dropdown-item"
                                onClick={e => {
                                  trackEvent('Clicou no Menu Cadastros > Cadastros Auxiliares > Estágio de Aplicação');
                                  this.closeAllMenu();
                                }}>
                                Estágio de Aplicação
                              </Link>
                            }
                            <Link to="/identificadorCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Cadastros Auxiliares > Identificadores');
                                this.closeAllMenu();
                              }}>
                              Identificadores
                            </Link>

                            <Link to="/unidadeMedidaCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Cadastros Auxiliares > Unidade de Medida');
                                this.closeAllMenu();
                              }}>
                              Unidades de Medida
                            </Link>
                            <Link to="/veiculoCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Cadastros > Cadastros Auxiliares > Veículo de Transporte');
                                this.closeAllMenu();
                              }}>
                              Veículo de Transporte
                            </Link>
                          </div>
                        </div>

                      </div>
                    </li>


                    {!moduloAgricultura ? <React.Fragment /> :
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ MenuAgriculturaState: !this.state.MenuAgriculturaState });
                          }}
                        >
                          <i className="fa fa-leaf mr-2"></i>Agricultura
                        </Link>

                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.MenuAgriculturaState
                          })}
                        >
                          <Link to="/agricultura/planejamento" className="dropdown-item"
                            onClick={e => {
                              trackEvent('Clicou no Menu Agricultura > Planejamento');
                              this.closeAllMenu();
                            }}>
                            Planejamento
                          </Link>

                          <Link to="/agricultura/execucao" className="dropdown-item"
                            onClick={e => {
                              trackEvent('Clicou no Menu Agricultura > Execução');
                              this.closeAllMenu();
                            }}>
                            Execução
                          </Link>

                          <Link to="/agricultura/colheita" className="dropdown-item"
                            onClick={e => {
                              trackEvent('Clicou no Menu Agricultura > Colheita');
                              this.closeAllMenu();
                            }}>
                            Colheita
                          </Link>
                          <hr />
                          <Link to="/agricultura/producao/venda" className="dropdown-item"
                            onClick={e => {
                              trackEvent('Clicou no Menu Agricultura > Produção Agrícola > Venda de Produção');
                              this.closeAllMenu();
                            }}>
                            Venda de Produção
                          </Link>

                          <hr />
                          <Link to="/pluviometroDigital" className="dropdown-item"
                            onClick={e => {
                              trackEvent('Clicou no Menu Agricultura > Pluviômetro');
                              this.closeAllMenu();
                            }}>
                            Pluviômetro
                          </Link>
                        </div>
                      </li>
                    }

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuPatrimonioState: !this.state.MenuPatrimonioState });
                        }}
                      >
                        <i className="fa fa-landmark mr-2"></i>Patrimônio
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuPatrimonioState
                        })}
                      >
                        {/* <Link to="/agricultura/planejamento" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Patrimônio > Visão de Patrimônio');
                            this.closeAllMenu();
                          }}>
                          Visão de Patrimônio
                        </Link>
                        <hr /> */}

                        <Link to="/patrimonio/abastecimento" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Patrimônio > Abastecimento');
                            this.closeAllMenu();
                          }}>
                          Abastecimento
                        </Link>

                        {/* <Link to="/patrimonio/depreciacao" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Patrimônio > Depreciação');
                            this.closeAllMenu();
                          }}>
                          Depreciação
                        </Link> */}

                        <Link to="/patrimonio/manutencao" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Patrimônio > Manutenção');
                            this.closeAllMenu();
                          }}>
                          Manutenção
                        </Link>

                        {/* <Link to="/patrimonio/alerta" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Patrimônio > Alertas de Manutenção');
                            this.closeAllMenu();
                          }}>
                          Alertas de Manutenção
                        </Link> */}

                        <hr />
                        <Link to="/patrimonioCadastro" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Patrimônio > Cadastro de Patrimônio');
                            this.closeAllMenu();
                          }}>
                          Cadastro de Patrimônio
                        </Link>
                      </div>
                    </li>

                    {!moduloPecuaria ? <React.Fragment /> :
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ MenuPecuariaState: !this.state.MenuPecuariaState });
                          }}
                        >
                          <i className="mdi mdi-cow mr-2"></i>Pecuária
                        </Link>

                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.MenuPecuariaState
                          })}
                        >
                          <Link to="/pecuaria/movimentacao/implantacao" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Implantar Estoque Animal
                          </Link>

                          <Link to="/pecuaria/movimentacao/compra" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Compra de Animais
                          </Link>
                          <Link to="/pecuaria/movimentacao/nascimento" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Nascimento
                          </Link>
                          <Link to="/pecuaria/movimentacao/venda" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Venda de Animais
                          </Link>
                          <Link to="/pecuaria/movimentacao/outrasSaidas" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Outras Saídas
                          </Link>

                          <hr />

                          <Link to="/pecuaria/relacaoAnimais" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Relação de Animais
                          </Link>

                          <hr />

                          <Link to="/pecuaria/transferencia" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Transferência de Campo
                          </Link>

                          <Link to="/pecuaria/evolucao" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Evolução Animal
                          </Link>

                          <Link to="/pecuaria/pesagem" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Controle de Peso
                          </Link>

                          <hr />

                          <Link to="/pecuaria/manejo/planejamento" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Planejar Manejo Sanitário
                          </Link>

                          <Link to="/pecuaria/manejo/execucao" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Executar Manejo Sanitário
                          </Link>

                          <hr />

                          <div className="dropdown">
                            <Link
                              to="/#"
                              className="dropdown-item dropdown-toggle arrow-none"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  EmailState: !this.state.EmailState
                                });
                              }}
                            >
                              Relatórios <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: this.state.EmailState
                              })}
                              aria-labelledby="topnav-email"
                            >
                              <Link to="/pecuaria/relatorio/movimentacaoAnimal" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Movimentação Animal
                              </Link>

                              {/* <Link to="/pecuaria/relatorios/movimentacaoMes" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Movimentação por Mês
                              </Link>

                              <Link to="/pecuaria/relatorios/movimentacaoMes" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Animais por Campo
                              </Link> */}
                            </div>
                          </div>

                        </div>
                      </li>
                    }




                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuEstoqueState: !this.state.MenuEstoqueState });
                        }}
                      >
                        <i className="fa fa-boxes mr-2"></i>Estoque
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuEstoqueState
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EstoqueInsumosState: !this.state.EstoqueInsumosState
                              });
                            }}
                          >
                            Insumos <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EstoqueInsumosState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/estoque/implantacao" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Estoque > Implantar Estoque');
                                this.closeAllMenu();
                              }}>
                              Implantação Rápida de Estoque
                            </Link>
                            <Link to="/estoque/baixarManual" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Estoque > Baixa Manual de Estoque');
                                this.closeAllMenu();
                              }}>
                              Baixa Manual de Estoque
                            </Link>
                            <Link to="/estoque/transferencia" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Estoque > Transferência Estoque');
                                this.closeAllMenu();
                              }}>
                              Transferência Estoque
                            </Link>

                            <hr />

                            <Link to="/estoque/movimentacao/estoqueAtual" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Estoque > Relatórios > Estoque Atual');
                                this.closeAllMenu();
                              }}>
                              Relatório - Estoque Atual
                            </Link>
                            <Link to="/estoque/movimentacao/estoquePorPeriodo" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Estoque > Relatórios > Estoque Por Período');
                                this.closeAllMenu();
                              }}>
                              Relatório - Estoque Por Período
                            </Link>
                            <Link to="/estoque/movimentacao/reposicaoEstoqueMinimo" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Estoque > Relatórios > Reposição Estoque Mínimo');
                                this.closeAllMenu();
                              }}>
                              Relatório - Reposição Estoque Mínimo
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EstoqueProducaoState: !this.state.EstoqueProducaoState
                              });
                            }}
                          >
                            Produção Agrícola <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EstoqueProducaoState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/agricultura/producao/implantacao" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Agricultura > Produção Agrícola > Implantação Estoque');
                                this.closeAllMenu();
                              }}>
                              Implantação Estoque
                            </Link>

                            <Link to="/agricultura/producao/baixaManual" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Agricultura > Produção Agrícola > Baixa Manual Estoque');
                                this.closeAllMenu();
                              }}>
                              Baixa Manual Estoque
                            </Link>

                            <Link to="/agricultura/producao/transferencia" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Agricultura > Produção Agrícola > Transferência Estoque');
                                this.closeAllMenu();
                              }}>
                              Transferência Estoque
                            </Link>

                            <hr />

                            <Link to="/agricultura/producao/relatorioEstoque" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Agricultura > Produção Agrícola > Relatórios > Estoque Produção');
                                this.closeAllMenu();
                              }}>
                              Relatório - Estoque Produção
                            </Link>
                          </div>
                        </div>
                        <hr />

                        <Link to="/estoque/localEstoqueCadastro" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Estoque > Local de Estoque');
                            this.closeAllMenu();
                          }}>
                          Local de Estoque
                        </Link>
                      </div>
                    </li>


                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuFinanceiroState: !this.state.MenuFinanceiroState });
                        }}
                      >
                        <i className="fa fa-hand-holding-usd mr-2"></i>Financeiro
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuFinanceiroState
                        })}
                      >
                        {/* <Link to="/financeiro/agendaFinanceira" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Agenda Financeira
                        </Link>

                        <hr /> */}

                        <Link to="/financeiro/entradaNota" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Estoque > Entrada de Nota Fiscal');
                            this.closeAllMenu();
                          }}>
                          Entrada de Nota Fiscal
                        </Link>

                        <hr />

                        <Link to="/financeiro/contaPagar" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Financeiro > Contas a Pagar');
                            this.closeAllMenu();
                          }}>
                          Contas a Pagar
                        </Link>

                        <Link to="/financeiro/contaReceber" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Financeiro > Contas a Receber');
                            this.closeAllMenu();
                          }}>
                          Contas a Receber
                        </Link>

                        <Link to="/financeiro/fluxoCaixa" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Financeiro > Fluxo de Caixa');
                            this.closeAllMenu();
                          }}>
                          Fluxo de Caixa
                        </Link>

                        <hr />

                        <Link to="/financeiro/movimentacoesBancarias" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Financeiro > Movimentações Bancárias');
                            this.closeAllMenu();
                          }}>
                          Movimentações Bancárias
                        </Link>

                        <Link to="/financeiro/movimentacoesCaixa" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Financeiro > Movimentações de Caixa');
                            this.closeAllMenu();
                          }}>
                          Movimentações de Caixa
                        </Link>

                        <Link to="/financeiro/transferenciaContaBancoCaixa" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Financeiro > Transferência Banco/Caixa');
                            this.closeAllMenu();
                          }}>
                          Transferência Banco/Caixa
                        </Link>

                        <hr />

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                CadastrosFinanceiroState: !this.state.CadastrosFinanceiroState
                              });
                            }}
                          >
                            Cadastros <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.CadastrosFinanceiroState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/financeiro/contaBancoCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Financeiro > Cadastros > Conta Banco');
                                this.closeAllMenu();
                              }}>
                              Conta Banco
                            </Link>
                            <Link to="/financeiro/contaCaixaCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Financeiro > Cadastros > Conta Caixa');
                                this.closeAllMenu();
                              }}>
                              Conta Caixa
                            </Link>
                            <Link to="/financeiro/planoContaCadastro" className="dropdown-item"
                              onClick={e => {
                                trackEvent('Clicou no Menu Financeiro > Cadastros > Plano de Contas Gerencial');
                                this.closeAllMenu();
                              }}>
                              Plano de Contas Gerencial
                            </Link>
                            {/* <Link to="/financeiro/contaCartaoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cartão
                            </Link> */}
                            {/* <Link to="/financeiro/contaChequePreDatadoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cheque Pré-Datado
                            </Link> */}
                          </div>
                        </div>
                      </div>
                    </li>




                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuFiscalState: !this.state.MenuFiscalState });
                        }}
                      >
                        <i className="fa fa-file-invoice-dollar mr-2"></i>Fiscal
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuFiscalState
                        })}
                      >

                        <Link to="/fiscal/monitoramento/nfeSefaz" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Fiscal > Monitoramento NFe - SEFAZ');
                            this.closeAllMenu();
                          }}>
                          Monitoramento NFe - SEFAZ
                        </Link>

                        <Link to="/fiscal/nfe/emissao" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Fiscal > Emissão NFe');
                            this.closeAllMenu();
                          }}>
                          Emissão NFe de Produtor
                        </Link>

                        <hr />

                        <Link to="/fiscal/inscricaoFiscal" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Fiscal > Configuração Fiscal');
                            this.closeAllMenu();
                          }}>
                          Inscrição Fiscal - Produtor Rural
                        </Link>
                        <Link to="/fiscal/certificadoDigital" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Fiscal > Configuração Fiscal');
                            this.closeAllMenu();
                          }}>
                          Certificado Digital
                        </Link>
                        <hr />
                        <Link to="/fiscal/funrural" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Fiscal > FUNRURAL');
                            this.closeAllMenu();
                          }}>
                          Funrural
                        </Link>
                        <Link to="/fiscal/mensagemFiscal" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Fiscal > Mensagem Fiscal');
                            this.closeAllMenu();
                          }}>
                          Mensagem Fiscal
                        </Link>
                        <Link to="/fiscal/naturezaOperacaoCadastro" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Fiscal > Natureza de Operação');
                            this.closeAllMenu();
                          }}>
                          Natureza de Operação
                        </Link>
                        <Link to="/fiscal/tabelaTributaria" className="dropdown-item"
                          onClick={e => {
                            trackEvent('Clicou no Menu Fiscal > Tabela Tributária');
                            this.closeAllMenu();
                          }}>
                          Tabela Tributária
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state) {
  const { startSessionReducer } = state.entities;
  return { startSessionReducer };
};

function mapDispatchToProps(dispatch) {
  return {}
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar))