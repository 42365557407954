import axios from 'axios';

export const httpServiceApiDfe = {
    post,
    postFile,
    put,
    get,
    getFile,
    del
};

async function post(pathApiDfe, body) {
    return await req({ method: 'post', pathApiDfe: pathApiDfe, data: body });
}

async function postFile(pathApiDfe, body) {
    return await reqFile({ method: 'post', pathApiDfe: pathApiDfe, data: body });
}

async function put(pathApiDfe, body) {
    const response = await req({ method: 'put', pathApiDfe: pathApiDfe, data: body });
    return await response.data;
}

// return body only ok
async function get(pathApiDfe) {
    const response = await req({ method: 'get', pathApiDfe: pathApiDfe });
    return await response.data;
}

async function getFile(pathApiDfe, filename) {
    await downloadFile({ pathApiDfe: pathApiDfe, filename: filename });
}

async function del(pathApiDfe) {
    return await req({ method: 'delete', pathApiDfe: pathApiDfe });
}

/////////////////////////////////////////////////////////////////////////////////
async function req({ method, pathApiDfe, data }) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';
    
    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    var response = await axios({
        method: method,
        url: url,
        data: data
    });
    return response;
}

// req file
async function reqFile({ method, pathApiDfe, data }) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';

    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    var response = await axios({
        method: method,
        url: url,
        headers: { "Content-Type": `multipart/form-data; boundary=${data._boundary}` },
        data: data
    });
    return response;
}

// get file
async function downloadFile({ pathApiDfe, filename }) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';

    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    try {
        const response = await axios({
            method: 'GET',
            url: url,
            responseType: 'blob', // 'blob' é necessário para arquivos binários
        });

        // Cria uma URL temporária para o blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));

        // Cria um link temporário para forçar o download
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute('download', filename); // Nome do arquivo
        tempLink.click(); // Dispara o download

        // Opcional: Revoga o objeto URL para liberar memória
        window.URL.revokeObjectURL(fileURL);
    } catch (error) {
        console.error('Erro ao baixar o arquivo:', error);
        throw error;
    }
}
/////////////////////////////////////////////////////////////////////////////////