import { takeLatest, put } from 'redux-saga/effects';

import {
  tabelaTributaria,
  NEW_TABELA_TRIBUTARIA,
  NEW_TABELA_TRIBUTARIA_SUCCESS,
} from '@handler';

function* sagaNewTabelaTributaria(action) {

  yield put({ 
    type: NEW_TABELA_TRIBUTARIA_SUCCESS, 
    registro: tabelaTributaria.newRegistro,
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewTabelaTributaria() {
  yield takeLatest(NEW_TABELA_TRIBUTARIA, sagaNewTabelaTributaria)
}
