import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createEmitenteCadastro, updateEmitenteCadastro } from '../service';
import {
  CREATE_EMITENTE_CADASTRO,
  CREATE_EMITENTE_CADASTRO_PENDING,
  CREATE_EMITENTE_CADASTRO_SUCCESS,
  CREATE_EMITENTE_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateEmitenteCadastro(action) {
  yield put({ type: CREATE_EMITENTE_CADASTRO_PENDING })

  try {
    if (action.data.tipoPessoa === 'FISICA') {
      action.data.apelido = action.data.nome;
      if (action.data.indicadorInscrEstadual === null || action.data.indicadorInscrEstadual === undefined || action.data.indicadorInscrEstadual === '') {
        action.data.indicadorInscrEstadual = 'NAOCONTRIBUINTE';
      }
    } else {
      if (action.data.indicadorInscrEstadual === null || action.data.indicadorInscrEstadual === undefined || action.data.indicadorInscrEstadual === '') {
        action.data.indicadorInscrEstadual = 'CONTRIBUINTEISENTO';
      }
    }

    delete action.data.cidadeUf;

    if (action.data.emiteNfe) {
      if (!action.data.emitenteCnd.informarCnd) {
        action.data.emitenteCnd.numeroCnd = ''
        action.data.emitenteCnd.dataValidadeCnd = null
      }
    } else {
      action.data.codigoRegimeTributario = null
      action.data.formaContribuicaoFunrural = null
      action.data.observacaoFunrural = ''
      action.data.emitenteSerieNfe.serieNfe = null
      action.data.emitenteSerieNfe.proximoNroNfe = null
      action.data.emitenteCnd.informarCnd = false
      action.data.emitenteCnd.numeroCnd = ''
      action.data.emitenteCnd.dataValidadeCnd = null
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createEmitenteCadastro, {
        ...action.data
      });
    } else {
      yield call(updateEmitenteCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_EMITENTE_CADASTRO_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/fiscal/inscricaoFiscal');
    }

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_EMITENTE_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateEmitenteCadastro() {
  yield takeLatest(CREATE_EMITENTE_CADASTRO, sagaCreateEmitenteCadastro)
}
