import React from 'react';
import {
    Col,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from 'reactstrap';
import { Field } from 'formik';
import { InfoTooltip } from './InfoTooltip';

export const InputDate = (props) => {
    const { col, name, label, placeholder, plusChange, type, renderInfoTooltip, titleInfoTooltip, msgInfoTooltip, bottomText } = props;

    // Gerar um ID único baseado no nome do campo
    const idBase = name.replace(/[\[\]\.]/g, '_');

    return (
        <Col md={col}>
            <FormGroup>
                <Label htmlFor={'id_' + name} >{label} {" "}
                    {
                        renderInfoTooltip ?
                            <InfoTooltip
                                id={'idtool_' + idBase}
                                title={titleInfoTooltip}
                                msg={msgInfoTooltip}
                            />
                            : <React.Fragment />
                    }
                </Label>
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <>
                            <Input
                                {...field}
                                name={name}
                                value={field.value ?? ''}
                                placeholder={placeholder}
                                type={type === 'datetime' ? 'datetime-local' : 'date'}
                                id={'id_' + name}
                                invalid={meta.error && meta.touched}
                                autoComplete="off"
                                onChange={(e) => {
                                    form.handleChange(e);
                                    plusChange(e);
                                }}
                            />
                            <FormFeedback>{meta.error}</FormFeedback>
                            <span style={{ fontStyle: 'italic', fontSize: '12px' }}>{bottomText}</span>
                        </>
                    )}
                </Field>
            </FormGroup>
        </Col>
    );
};

InputDate.defaultProps = {
    col: 12,
    type: 'date',
    renderInfoTooltip: false,
    titleInfoTooltip: 'Informação',
    plusChange: () => { }
};
